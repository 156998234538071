import { defineStore } from "pinia";

import { ref, computed } from "vue";
import { RemarkService } from "@/services/remark";
import { useDeploymentStore } from "../Deployment/DeploymentStore";
import { Remark } from "@/services/remark/types";

export const useRemarkStore = defineStore("RemarkStore", () => {
  const remarks = ref<Remark[]>([]);
  const deploymentStore = useDeploymentStore();

  /** Getters */

  const getRemarksByBookingId = computed(
    () =>
      (bookingId: number): Remark[] => {
        return remarks.value.filter(
          (remark) => remark.booking_id === bookingId
        );
      }
  );

  const getRemarks = computed(
    () =>
      (bikeId?: number, bookingId?: number): Remark[] => {
        // If booking id is provided, return remarks for that booking
        if (bookingId) {
          return remarks.value
            .filter((remark) => remark.booking_id === bookingId)
            .sort((a, b) => {
              return (
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
              );
            });
        } else if (bikeId) {
            let bikeBookings = deploymentStore.getDeployment?.bicycles.find(b => b.deployment_bicycle_id === bikeId)?.bookings;
            return remarks.value
                .filter((remark) => bikeBookings?.find(b => b.id === remark.booking_id))
                .sort((a, b) => {
                    return (
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                    );
                });
        }
        return [];
      }
  );

  /** Actions */

  /**
   * Add a remark to the store
   * @param remark
   */
  const addRemark = (remark: Remark) => {
    remarks.value.push(remark);
  };

  /**
   * Add multiple remarks to the store
   * @param remarks
   */
  const addRemarks = (newRemarks: Remark[]) => {
    // Add or Replace remarks in the store with the new remarks
    newRemarks.forEach((remark) => {
      let index = newRemarks.findIndex((r) => r.id === remark.id);
      if (index === -1) {
        addRemark(remark);
      } else {
        remarks.value[index] = remark;
      }
    });
  };

  /**
   * Create a new remark
   *
   */
  const createRemark = async (
    bikeId: number,
    remark: string,
    bookingId: number | null
  ) => {
    let newRemark = await RemarkService.createRemark(bikeId, remark, bookingId);
    if (newRemark) {
      addRemark(newRemark);
    }
    return newRemark;
  };

  /**
   * Fetch remarks by booking id
   */
  const fetchRemarksByBicycleId = async (bikeId: number) => {
    let fetchedRemarks = await RemarkService.fetchRemarksByBicycleId(bikeId);
    if (fetchedRemarks) {
      addRemarks(fetchedRemarks);
    }
    return fetchedRemarks;
  };

  /**
   * Fetch remarks by booking id
   */
  const fetchRemarksByBookingId = async (bookingId: number) => {
    let fetchedRemarks = await RemarkService.fetchRemarksByBookingId(bookingId);
    if (fetchedRemarks) {
      addRemarks(fetchedRemarks);
    }
    return fetchedRemarks;
  };

  return {
    remarks,
    addRemark,
    addRemarks,
    createRemark,
    getRemarksByBookingId,
    getRemarks,
    fetchRemarksByBicycleId,
    fetchRemarksByBookingId,
  };
});
