import FileUpload from './FileUpload';
import global from './global';
import toast from './toast';
import OverlayPanel from './OverlayPanel';
import Skeleton from './Skeleton';
import Panel from './Panel';
import InputOtp from './InputOtp';
import Image from './Image';
import Dialog from './Dialog';
import DatePicker from './DatePicker';
import Tag from './Tag';

export default {
  global,
  toast,
  OverlayPanel,
  FileUpload,
  Skeleton,
  Panel,
  InputOtp,
  Image,
  Dialog,
  DatePicker,
  Tag,
  // Directives
  directives: {
    tooltip: {
      root: ({ context }) => ({
        class: [
          // Position and Shadows
          "absolute",
          "p-fadein",
          // Spacing
          {
            "py-0 px-1":
              (context == null ? void 0 : context.right) ||
              (context == null ? void 0 : context.left) ||
              (!(context != null && context.right) &&
                !(context != null && context.left) &&
                !(context != null && context.top) &&
                !(context != null && context.bottom)),
            "py-1 px-0":
              (context == null ? void 0 : context.top) ||
              (context == null ? void 0 : context.bottom),
          },
        ],
      }),
      arrow: ({ context }) => ({
        class: [
          // Position
          "absolute",
          // Size
          "w-0",
          "h-0",
          // Shape
          "border-transparent",
          "border-solid",
          {
            "border-y-[10px] border-r-[10px] border-l-0 border-r-[#202939]":
              (context == null ? void 0 : context.right) ||
              (!(context != null && context.right) &&
                !(context != null && context.left) &&
                !(context != null && context.top) &&
                !(context != null && context.bottom)),
            "border-y-[10px] border-l-[10px] border-r-0 border-l-[#202939]":
              context == null ? void 0 : context.left,
            "border-x-[10px] border-t-[10px] border-b-0 border-t-[#202939]":
              context == null ? void 0 : context.top,
            "border-x-[10px] border-b-[10px] border-t-0 border-b-[#202939]":
              context == null ? void 0 : context.bottom,
          },
          // Spacing
          {
            "-mt-[10px] top-1/2":
              (context == null ? void 0 : context.right) ||
              (context == null ? void 0 : context.left) ||
              (!(context != null && context.right) &&
                !(context != null && context.left) &&
                !(context != null && context.top) &&
                !(context != null && context.bottom)),
            "-ml-[10px] left-1/2":
              (context == null ? void 0 : context.top) ||
              (context == null ? void 0 : context.bottom),
          },
        ],
      }),
      text: {
        class: [
          "p-3",
          "bg-[#202939]",
          "text-white text-sm",
          "leading-none",
          "rounded-md",
          "whitespace-pre-line",
          "break-words",
        ],
      },
    },

  },
};
