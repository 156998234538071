export default {
  root: {
    class: 'bg-primary-50 rounded-xl border-2 border-primary-100 grid grid-cols-1 lg:grid-cols-2',
  },
  body: {
    class: "px-5 py-2.5"
  },
  header: {
    class:
            'flex items-center justify-center bg-white overflow-hidden rounded-t-xl lg:rounded-none relative',
  },
  title: {
    class:
            'py-2 flex flex-row justify-between items-start',
  },
  content: {
    class: 'pb-2 font-roboto flex flex-row items-center lg:justify-between gap-4',
  },
}