<template>
  <form-step>
    <form-layout class="col-span-2 h-full">
      <template v-slot:title>
        {{ $t("testfleet.booking.requirements.title") }}
      </template>

      <template v-slot:description>
        {{ $t("testfleet.booking.requirements.description") }}
      </template>

      <template v-slot:form>
        <div class="flex flex-col gap-6 pb-12 lg:pb-0 px-8">
          <div class="flex flex-col gap-6">
            <div class="flex gap-4">
              <Field data-cy="lock-requirement" type="checkbox" name="requirements.lock" :value="true" />
              <label for="requirements.lock" class="font-roboto cursor-pointer"
                v-html="$t('testfleet.booking.requirements.lock')" @click="lockField.setValue(!lockField.value.value)">
              </label>
            </div>
          </div>
          <div class="flex flex-col gap-6" v-if="bikeType === 'speed_pedelec'">
            <hr class="border-primary-100" />
            <div class="flex gap-4">
              <Field data-cy="helmet-requirement" type="checkbox" name="requirements.helmet" :value="true" />
              <label for="requirements.helmet" class="font-roboto cursor-pointer"
                v-html="$t('testfleet.booking.requirements.helmet')"
                @click="helmetField.setValue(!helmetField.value.value)">
              </label>
            </div>
          </div>
          <div class="flex flex-col gap-6" v-if="bikeType === 'speed_pedelec'">
            <hr class="border-primary-100" />
            <div class="flex gap-4">
              <Field data-cy="license-requirement" type="checkbox" name="requirements.license" :value="true" />
              <label for="requirements.license" class="font-roboto cursor-pointer"
                v-html="$t('testfleet.booking.requirements.license')"
                @click="e => isNotLinkClick(e) && licenseField.setValue(!licenseField.value.value)">
              </label>
            </div>
          </div>
          <div class="flex flex-col gap-6" v-if="bikeType === 'speed_pedelec'">
            <hr class="border-primary-100" />
            <div class="flex gap-4">
              <Field data-cy="terms-gdpr" type="checkbox" name="requirements.gdpr" :value="true" />
              <div class="inline-flex gap-1 items-center">
                <label for="requirements.gdpr" class="font-roboto [&>a]:font-bold [&>a]:underline cursor-pointer"
                  v-html="$t('testfleet.booking.requirements.gdpr')"
                  @click="e => isNotLinkClick(e) && gdprField.setValue(!gdprField.value.value)">
                </label>
                <InformationOutline :size="20"
                  class="text-neutral-600"
                  v-tooltip="$t('testfleet.booking.requirements.gdprInfo')" />
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-6">
            <hr class="border-primary-100" />
            <div class="flex gap-4">
              <Field data-cy="terms-requirement" type="checkbox" name="requirements.terms" :value="true" />
              <label for="requirements.terms" class="font-roboto [&>a]:font-bold [&>a]:underline cursor-pointer"
                v-html="$t('testfleet.booking.requirements.terms')"
                @click="e => isNotLinkClick(e) && termsField.setValue(!termsField.value.value)">
              </label>
            </div>
          </div>
          <div class="flex flex-col gap-6">
            <hr class="border-primary-100" />
            <div class="flex gap-4">
              <Field data-cy="privacy-requirement" type="checkbox" name="requirements.privacy" :value="true" />
              <label for="requirements.privacy" class="font-roboto [&>a]:font-bold [&>a]:underline cursor-pointer"
                v-html="$t('testfleet.booking.requirements.privacy')"
                @click="e => isNotLinkClick(e) && privacyField.setValue(!privacyField.value.value)">
              </label>
            </div>
          </div>
        </div>
      </template>
    </form-layout>
  </form-step>
</template>

<script setup lang="ts">
import FormStep from "@/components/Form/FormStep.vue";
import FormLayout from "@/components/Form/FormLayout.vue";
import { Field, useField } from "vee-validate";
// @ts-ignore
import InformationOutline from 'vue-material-design-icons/InformationOutline.vue'

const lockField = useField("requirements.lock");
const helmetField = useField("requirements.helmet");
const licenseField = useField("requirements.license");
const termsField = useField("requirements.terms");
const privacyField = useField("requirements.privacy");
const gdprField = useField("requirements.gdpr");

const isNotLinkClick = (e: MouseEvent) => {
  const target = e.target as HTMLElement;
  return target.tagName !== "A";
};

const { bikeType } = defineProps({
  bikeType: {
    type: String,
    required: true
  }
})
</script>
