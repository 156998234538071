import { Booking, BookingStatus } from "@/services/booking/types";
import { DeploymentService } from "@/services/deployment";
import { Deployment, DeploymentUpdateDto } from "@/services/deployment/types";
import { defineStore } from "pinia";
import { useRemarkStore } from "../Remark/RemarkStore";
import { Bike } from "@/services/bikes/types";
import { computed, ref } from "vue";
import { Remark } from "@/services/remark/types";

export const useDeploymentStore = defineStore("DeploymentStore", () => {
  const deployment = ref<Deployment | null>(null);

  /** Getters */

  const getDeployment = computed(() => deployment.value);

  const getPickupTime = computed(() => {
    if (!deployment.value) {
      return null;
    }
    return deployment.value.pickup_timeslot_start + " - " + deployment.value.pickup_timeslot_end;
  });

  const getDropoffTime = computed(() => {
    if (!deployment.value) {
      return null;
    }
    return deployment.value.dropoff_timeslot_start + " - " + deployment.value.dropoff_timeslot_end;
  });

  /** Actions */
  const getActiveForUser = async () => {
    const deploymentIds = await DeploymentService.getIdsForUser();
    if (deploymentIds.length === 0) {
      return;
    }
    await getById(deploymentIds[0]);
    if (!deployment.value) {
      return;
    }
    setActive(deployment.value);
  };

  const getById = async (id: number) => {
    const deploymentData = await DeploymentService.getById(id);
    if (!deploymentData) {
      return;
    }
    deployment.value = deploymentData;
  };

  const getByCode = async (code: string) => {
    const deploymentData = await DeploymentService.getByCode(code);
    if (!deploymentData) {
      return;
    }
    deployment.value = deploymentData;
  };

  const fetchBookings = async () => {
    if (!deployment.value) {
      return;
    }
    let bookings = (await DeploymentService.fetchBookings(deployment.value.id))
      .data;

    deployment.value.bicycles = bookings.map((b: any) => {
      return {
        ...b,
        bookings:
          b.bookings?.map((booking: any) => {
            return {
              ...booking,
              start_date: new Date(booking.start_date),
              end_date: new Date(booking.end_date),
            };
          }) || [],
      };
    });

    let remarks: Remark[] = [];

    bookings.forEach((b: Bike) => {
      b.bookings?.forEach((booking: Booking) => {
        booking.remarks?.forEach((remark: Remark) => {
          remarks.push(remark);
        });
      });
    });

    useRemarkStore().addRemarks(remarks);
  };

  const setActive = (deploymentData: Deployment) => {
    deployment.value = deploymentData;
  };

  const getDeploymentLink = () => {
    return window.location.origin + "/?code=" + deployment.value?.login_code;
  };

  const updateDeployment = async (deploymentDto : DeploymentUpdateDto) => {
    if (!deployment.value) {
      return;
    }

    const response = await DeploymentService.updateDeployment(
      deployment.value.id,
      deploymentDto
    );
    // Update the deployment with the new data
    deployment.value = {
      ...deployment.value,
      ...response.data,
    }
  };

  const confirmHRPickup = async (bookingId: number, picked_up_helmet : boolean) => {
    await DeploymentService.confirmHRPickup(bookingId, picked_up_helmet);
    // Update the booking for that bicycle in the deployment
    if (!deployment.value) {
      return;
    }

    deployment.value.bicycles.forEach((b) => {
      b.bookings?.forEach((booking) => {
        if (booking.id === bookingId){
          booking.admin_pickup_confirmed = true;
          booking.picked_up_helmet = picked_up_helmet;
        } 
      });
    });
  };

  const confirmHRDropoff = async (bookingId: number) => {
    await DeploymentService.confirmHRDropoff(bookingId);

    // Update the booking for that bicycle in the deployment
    if (!deployment.value) {
      return;
    }

    deployment.value.bicycles.forEach((b) => {
      b.bookings?.forEach((booking) => {
        if (booking.id === bookingId) {
          booking.admin_dropoff_confirmed = true;
        }
      });
    });
  };

  const cancel = async (bookingId: number) => {
    await DeploymentService.cancel(bookingId);

    // Update the booking for that bicycle in the deployment
    if (!deployment.value) {
      return;
    }

    deployment.value.bicycles.forEach((b) => {
      b.bookings?.forEach((booking) => {
        if (booking.id === bookingId) booking.status = BookingStatus.Cancelled;
      });
    });
  };

  const inactivateBike = async (bikeId: number) => {
    let response = await DeploymentService.inactivateBike(deployment.value?.id!, bikeId);


    let deploymentBikeBookings = response.data.bookings;

    // Update the booking for that bicycle in the deployment
    if (!deployment.value) {
      return;
    }

    // Set the bike to inactibe
    deployment.value.bicycles.forEach((b) => {
      if (b.id === bikeId) b.inactive = true;
    });

    // Update the status of the bookings of the bike with the new status
    deployment.value.bicycles.forEach((b) => {
      if (b.id === bikeId) {
        // Update the status of the bookings of the bike with the new status
        b.bookings?.forEach((booking) => {
          deploymentBikeBookings.forEach((newBooking: any) => {
            if (booking.id === newBooking.id) {
              booking.status = newBooking.status;
            }
          });
        });
      }
    });
  };

  const attachUserToDeployment = async (code: string) => {
    await DeploymentService.attachUserToDeployment(code);
  };

  return {
    getDeployment,
    getPickupTime,
    getDropoffTime,
    getActiveForUser,
    getById,
    getByCode,
    fetchBookings,
    setActive,
    getDeploymentLink,
    updateDeployment,
    confirmHRPickup,
    confirmHRDropoff,
    cancel,
    inactivateBike,
    attachUserToDeployment,
  };
});
