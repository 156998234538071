<template>
    <main class="flex min-w-screen min-h-screen font-sans">
        <div class="flex flex-col w-full gap-[30px] lg:items-center">
            <Navbar />
            <slot />
        </div>
    <Toast />
    </main>
</template>

<script setup lang="ts">
  import Toast from 'primevue/toast'
  import Navbar from '@/components/Navbar/Navbar.vue'

import {onBeforeMount} from 'vue';
import { LocalStorageService } from '@/services/localstorage';
import { AuthType } from '@/router/AuthGuard';
import { useRouter } from 'vue-router';

const router = useRouter();


onBeforeMount(() => {
    const authType = LocalStorageService.getAuthType();
    // check if current route is login and user is authenticated
    // if yes, redirect to home
    if(authType === AuthType.JWT_TOKEN && window.location.pathname === '/login') {
      router.push({ path: '/' })
    }
});



</script>
