<script setup lang="ts">
import { TolgeeProvider } from '@tolgee/vue';
import Intercom from '@intercom/messenger-js-sdk';
import { useUserStore } from './stores/User/UserStore';
import { computed } from 'vue';
const userStore = useUserStore();

const user = computed(() => userStore.getUser);
const user_hash = computed(() => userStore.getUserIntercomHash);

Intercom({
   app_id: import.meta.env.VITE_INTERCOM_APP_ID as string,
   region: 'eu',
   email: user.value ? user.value.email : undefined,
   user_hash: user_hash.value ? user_hash.value : undefined,
})

</script>

<template>
   <TolgeeProvider>
      <component :is="$route.meta.layout || 'div'">
         <router-view></router-view>
      </component>
   </TolgeeProvider>
</template>


<style scoped></style>
