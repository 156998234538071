<template>
    <div class="flex justify-center py-4 flex-1">
        <div class="flex flex-col rounded-lg gap-8 px-2">
            <form-layout class="col-span-2 h-full">
                <template v-slot:title>
                    {{ $t("testfleet.booked.dropoff.title") }}
                </template>

                <template v-slot:description>
                    <span
                        v-html="$t('testfleet.booked.dropoff.description', { bike: booking?.bicycle?.brand + ' ' + booking?.bicycle?.model })"></span>
                </template>
                <template v-slot:form>
                    <form class="space-y-10 px-8" v-if="booking" @submit="onSubmit" :validation-schema="schema">

                        <ReservationDetail :location="booking.deployment.location" :booking="booking"
                            :booking-status="BookingStatus.PickedUp"></ReservationDetail>

                        <h3 class="text-start text-base font-semibold">
                            {{ $t("testfleet.booked.dropoff.steps.title") }}
                        </h3>

                        <div class="flex flex-col space-y-10">
                            <div class="flex flex-col gap-6">
                                <div class="flex gap-3">
                                    <div
                                        class="min-w-9 h-9 flex items-center justify-center bg-tertiary text-white font-bold font-roboto text-lg rounded-full">
                                        1
                                    </div>
                                    <h2 class="font-semibold">
                                        {{ $t("testfleet.booked.pickup.steps.pictures.title") }}
                                    </h2>
                                </div>
                                <span class="text-base font-roboto">
                                    {{ $t("testfleet.booked.dropoff.steps.pictures.description") }}
                                </span>
                                <!-- Picture Uploader -->
                                <div class="flex flex-col gap-2.5 px-5 pt-6 pb-6 border border-primary-100 rounded-xl">
                                    <h3 class="text-neutral font-semibold text-base">
                                        {{ $t("testfleet.booked.pickup.steps.pictures.upload.title") }}
                                    </h3>

                                    <FileUpload v-on:remove="onRemoveFile" v-on:select="onSelectFile"></FileUpload>
                                    <!--  -->
                                    <h3 class="text-neutral font-semibold text-base">
                                        {{ $t("testfleet.booked.pickup.steps.pictures.damage.title") }}
                                    </h3>
                                    <Field class="w-full h-24 bg-primary-50 rounded-lg p-3 resize-none" name="damage"
                                        as="textarea"
                                        :placeholder="$t('testfleet.booked.pickup.steps.pictures.damage.placeholder')" />
                                </div>

                            </div>
                            <div class="flex flex-col gap-6">
                                <div class="flex gap-3">
                                    <div
                                        class="min-w-9 h-9 flex items-center justify-center bg-tertiary text-white font-bold font-roboto text-lg rounded-full">
                                        2</div>
                                    <h2 class="font-semibold">
                                        {{ $t("testfleet.booked.dropoff.steps.recievedItems.title") }}
                                    </h2>
                                </div>
                                <span class="text-base text-neutral-700 font-roboto ps-6">
                                    <ul class="list-disc list-inside space-y-4">
                                        <li class="marker:text-tertiary">
                                            <span class="text-center">
                                                {{ $t("testfleet.booked.pickup.steps.recievedItems.items.charger") }}
                                            </span>
                                        </li>
                                        <li class="marker:text-tertiary">
                                            <span class="text-center">
                                                {{ $t("testfleet.booked.pickup.steps.recievedItems.items.lock") }}
                                            </span>
                                        </li>
                                        <li class="marker:text-tertiary">
                                            <span class="text-center">
                                                {{ $t("testfleet.booked.pickup.steps.recievedItems.items.helmet") }}
                                            </span>
                                        </li>
                                        <li class="marker:text-tertiary">
                                            <span class="text-center">
                                                {{ $t("testfleet.booked.pickup.steps.recievedItems.items.bag") }}
                                            </span>
                                        </li>
                                    </ul>
                                </span>
                            </div>

                            <hr class="h-[2px] bg-primary-100">
                            <!-- Check Box -->
                            <div class="flex gap-2 font-roboto items-center">
                                <Field type="checkbox" class="h-4 w-4 text-tertiary bg-primary" name="acceptedTerms"
                                    :value="true" />
                                    <span v-html="$t('testfleet.booked.dropoff.steps.confirmation')"
                                    class="[&>a]:font-bold [&>a]:underline cursor-pointer" @click="setValues({ acceptedTerms: !values.acceptedTerms });"></span>
                            </div>
                            <!-- Buttons -->
                            <div class="flex gap-3">
                                <Button
                                variant="ghost"
                                type="button"
                                @click="() => router.push({ path: '/my-bookings' })"
                                class="ps-4 pe-6 py-3 gap-2 text-sm flex items-center justify-center"
                                iconLeft="ArrowLeft"
                                :label="$t('testfleet.booking.form.button.back')"
                                :disabled="loading"
                                />

                                <Button
                                @click="onSubmit"
                                data-cy="next-btn"
                                :disabled="disableNext || loading"
                                class="ps-4 pe-6 py-3 gap-2 text-nowrap text-sm flex items-center justify-center w-full"
                                :label="$t('testfleet.booked.dropoff.submit')"
                                :loading="loading"
                                />
                            </div>
                        </div>
                    </form>
                </template>
            </form-layout>
        </div>
    </div>
</template>

<script setup lang="ts">
import FormLayout from '../../components/Form/FormLayout.vue'
import { useUserStore } from '../../stores/User/UserStore';
import { storeToRefs } from 'pinia';
import ReservationDetail from '../../components/Booking/ReservationDetail/ReservationDetail.vue';
import { computed } from 'vue';
import Button from '@/components/Button/Button.vue';
import * as yup from 'yup';
import { useForm, Field, useField } from 'vee-validate';
import { useRoute, useRouter } from 'vue-router';
import FileUpload from '../../components/FileUpload/FileUpload.vue';
import { Booking, BookingStatus } from '@/services/booking/types';
import { useBookingStore } from '@/stores/Booking/BookingStore';
import { useToast } from 'primevue/usetoast';
import { useTolgee } from '@tolgee/vue';
import { FileUploadRemoveEvent, FileUploadUploadEvent } from 'primevue/fileupload';
import { onBeforeMount } from 'vue'
import { ref } from 'vue'

const { user } = storeToRefs(useUserStore());
const booking = ref<Booking | null>(null);
const route = useRoute();
const router = useRouter();
const toast = useToast();
const tolgee = useTolgee();

onBeforeMount(() => {
  let bookingId: number;
  if (Array.isArray(route.params.id)) {
    bookingId = parseInt(route.params.id[0]);
  } else {
    bookingId = parseInt(route.params.id);
  }
  const foundBooking = user.value?.bookings.find(booking => booking.id === bookingId);
  if (foundBooking) {
    booking.value = foundBooking;
  } else {
    router.push({ path: '/' });
  }
});

const schema = yup.object({
    acceptedTerms: yup.bool().oneOf([true]).default(false),
    files: yup.array().min(2).max(5).required(),
    damage: yup.string().optional(),
});

const { handleSubmit, errors, setValues, values } = useForm({
    // vee-validate will be aware of computed schema changes
    validationSchema: schema,
    // turn this on so each step values won't get removed when you move back or to the next step
    keepValuesOnUnmount: true,
    validateOnMount: true,
});

const loading = ref(false);

const files = useField('files');
const disableNext = computed(() => Object.keys(errors.value).length > 0);

const onSubmit = handleSubmit(async (values) => {

    loading.value = true;
    // Sending api call ...
    if (booking.value) {
        // Create a FormData object
        const formData = new FormData();

        // Append non-file fields
        formData.append('damage_description', values.damage || '');

        for (const file of values.files) {
            const response = await fetch(file.objectURL);
            const blob = await response.blob();
            formData.append('images[]', blob, file.name);
        }

        useBookingStore().confirmDropOff(booking.value.id, formData).then(() => {
            router.push({ path: '/', query: {droppedOff : booking.value?.id} });
        }).catch((error) => {
            if(error.response.data.errors['status']) {
              switch(error.response.data.errors['status']) {
                case 'NOT_PICKED_UP':
                      toast.add({
                      severity: 'error', summary: tolgee.value.t('testfleet.booking.form.errors.dropoffFailed.title'),
                      detail: tolgee.value.t('testfleet.booking.form.errors.dropoffFailed.description.status')
                  });
                  break;
                case 'DROP_OFF_NOT_POSSIBLE':
                default:
                  toast.add({
                      severity: 'error', summary: tolgee.value.t('testfleet.booking.form.errors.dropoffFailed.title'),
                      detail: tolgee.value.t('testfleet.booking.form.errors.dropoffFailed.description')
                  });
              }
            }
        }).finally(() => {
            loading.value = false;
        })
    }
})

const onSelectFile = (event: FileUploadUploadEvent) => {
    // Check if event.files is an array or a single file
    if (Array.isArray(event.files)) {
        files.setValue(event.files);
    } else {
        files.setValue([event.files]);
    }
};
const onRemoveFile = (event : FileUploadRemoveEvent) => {
    files.setValue(event.files);
};


</script>
