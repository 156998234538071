<template>
    <div class="flex flex-col h-full pt-4 flex-1 w-full px-10 pb-12">
        <DataTable class="w-full" :value="bikes" :expandedRows="expandedRows" dataKey="id" size="large" v-if="!loading"
            sortField="bicycle_code" :sortOrder="1"  :rowClass="rowClass" @row-click="onRowClick"
             :pt="CalendarTable">
            <template #header>
                <div class="flex flex-col md:flex-row gap-8">
                    <h1 class="text-2xl font-medium">
                        {{ $t('testfleet.hr.calendar.title', {
            count: filters.start_date === null ? bikes.length : bikes.length + '/' +
                deploymentStore.getDeployment?.bicycles.length
        }) }}
                    </h1>
                    <div class="flex flex-col md:flex-row gap-4 justify-between flex-1">
                        <div class=" flex gap-4">

                            <button @click="expandAll" class="flex items-center gap-1 text-sm"
                                v-tooltip.top="$t('testfleet.hr.calendar.tooltip.expand')">
                                <UnfoldMoreHorizontal :size="20" />
                                {{ $t('testfleet.hr.calendar.expand.button') }}
                            </button>
                            <button @click="collapseAll" class="flex items-center gap-1 text-sm"
                                v-tooltip.top="$t('testfleet.hr.calendar.tooltip.collapse')">
                                <UnfoldLessHorizontal :size="20" />
                                {{ $t('testfleet.hr.calendar.collapse.button') }}
                            </button>
                        </div>
                        <!-- Filter Functionality -->
                        <div class="flex gap-2">
                            <button class="flex items-center gap-1 text-sm font-medium px-4 py-2 hover:bg-primary-50"
                                :class="{ 'bg-primary-50 rounded border border-primary-100': filters.start_date === today }"
                                @click="filterOnToday"
                                v-tooltip.top="formatDate(today, $tolgee.getLanguage()!, 'long')">
                                {{ $t('testfleet.hr.calendar.filter.today') }}</button>

                            <VDatePicker expanded mode="date" :masks="{ date: 'YYYY-MM-DD' }" v-model="filterDate"
                                :minDate="deploymentStore.getDeployment?.start_date"
                                :maxDate="deploymentStore.getDeployment?.end_date" :popover="{ placement: 'top-end' }"
                                class="py-0" :locale="$tolgee.getLanguage()"
                                v-tooltip.top="filters.start_date && filters.start_date !== today ? formatDate(filters.start_date, $tolgee.getLanguage()!, 'long') : $t('testfleet.hr.calendar.filter.date')">
                                <template #default="{ inputValue, inputEvents }">

                                    <input type="text" :placeholder="$t('testfleet.hr.calendar.filter.date')"
                                        @click="filters.start_date !== today ? filterDate = '' : null" readonly
                                        v-tooltip.top="filters.start_date && filters.start_date !== today ? formatDate(filters.start_date, $tolgee.getLanguage()!, 'long') : $t('testfleet.hr.calendar.tooltip.filter.date')"
                                        class="flex items-center gap-1 text-sm font-medium px-4 py-2 hover:bg-primary-50 text-nowrap max-w-[8rem] placeholder:text-neutral-900 text-center hover:cursor-pointer"
                                        :class="{ 'bg-primary-50 rounded border border-primary-100': filters.start_date && filters.start_date !== today }"
                                        :value="inputValue" v-on="inputEvents" data-cy='birth_date' />

                                </template>
                            </VDatePicker>
                        </div>
                    </div>
                </div>
            </template>

            <!-- Empty State -->

            <template #empty>
                <div class="w-full h-full flex flex-col justify-center items-center py-64 px-2">
                    <div class="bg-primary-300 w-32 h-32 rounded-full flex items-center justify-center">
                        <BikeIcon :size="48" class="text-white" />
                    </div>
                    <div class="flex flex-col justify-center items-center w-full" v-if="filters.start_date === null">
                        <h2 class="text-xl font-medium mt-8">
                            {{ $t('testfleet.hr.calendar.empty') }}
                        </h2>
                        <p class="text-md text-neutral-700 mt-4 md:w-[75%] text-center font-light">
                            {{ $t('testfleet.hr.calendar.empty.description') }}
                        </p>
                    </div>
                    <div class="flex flex-col justify-center items-center w-full" v-else>
                        <h2 class="text-xl font-medium mt-8">
                            {{ $t('testfleet.hr.calendar.empty.filter') }}
                        </h2>
                        <p class="text-md text-neutral-700 mt-4 md:w-[75%] text-center font-light">
                            {{ $t('testfleet.hr.calendar.empty.filter.description') }}
                        </p>
                    </div>
                </div>
            </template>



            <!-- Column Expander -->
            <Column expander style="width: 16px;" />

            <!-- Column Bicycle Code -->
            <Column field="bicycle_code" header="Bicycle Code" sortable style="width: fit-content;">
                <template #body="slotProps">
                    <BikeCode :bicycleCode="slotProps.data.bicycle_code" class="w-fit" />
                </template>
            </Column>

            <!-- Column Bike Name -->
            <Column field="name" header="name">
                <template #body="slotProps">
                    <span class="font-semibold text-neutral-900">
                        {{ slotProps.data.brand }} {{ slotProps.data.model }}
                    </span>
                </template>
            </Column>


            <Column class="w-full" />

            <Column field="remarks" class="pe-2">
                <template #body="slotProps">
                    <RemarkModal :bike="slotProps.data" :header="true" />
                </template>
            </Column>
            
            <Column field="inactive" class="ps-0">
                <template #body="slotProps">
                    <DeactivateBike :bike="slotProps.data" />

                </template>
            </Column>

            <!-- Table Expansion -->

            <template #expansion="slotProps: { data: Bike }">

                <BookingTable :bike="slotProps.data" :bookings="slotProps.data.bookings ?? []" />

            </template>

        </DataTable>

    </div>



</template>

<script setup lang="ts">
// @ts-ignore
import UnfoldMoreHorizontal from "vue-material-design-icons/UnfoldMoreHorizontal.vue";
// @ts-ignore
import UnfoldLessHorizontal from "vue-material-design-icons/UnfoldLessHorizontal.vue";
// @ts-ignore
import CheckCircleOutline from "vue-material-design-icons/CheckCircleOutline.vue";
// @ts-ignore
import CheckCircle from "vue-material-design-icons/CheckCircle.vue";
// @ts-ignore
import CloseCircleOutline from "vue-material-design-icons/CloseCircleOutline.vue";
// @ts-ignore
import ChevronRight from "vue-material-design-icons/ChevronRight.vue";
// @ts-ignore
import CalendarAlertOutline from "vue-material-design-icons/CalendarAlertOutline.vue";
// @ts-ignore
import Check from "vue-material-design-icons/Check.vue";
// @ts-ignore
import Close from "vue-material-design-icons/Close.vue";
// @ts-ignore
import BikeIcon from "vue-material-design-icons/Bike.vue";
import { ref, computed, onMounted } from 'vue';
import { Bike } from '@/services/bikes/types'
import { useDeploymentStore } from "@/stores/Deployment/DeploymentStore";
import DataTable from "primevue/datatable";
import CalendarTable from "@/presets/o2o/CalendarTable/index";
import Column from "primevue/column";
import BikeCode from "@/components/BikeCode/BikeCode.vue";
import { formatDate } from "@/utils/date";
import BookingTable from "@/components/Admin/BookingTable.vue";
import RemarkModal from "@/components/RemarkModal/RemarkModal.vue";
import { watch } from "vue";
import moment from 'moment';
// @ts-ignore
import Cancel from "vue-material-design-icons/Cancel.vue";
import DeactivateBike from "@/components/Admin/DeactivateBike.vue";

onMounted(async () => {
    loading.value = true;
    await deploymentStore.fetchBookings();
    loading.value = false;
});

const loading = ref(false);


const today = computed(() => new Date());

const filters = ref<{ start_date: null | Date }>({
    start_date: null,
});

const filterDate = ref('');


watch(filterDate, (newValue) => {
    if (newValue === '' || newValue === null) {
        filters.value.start_date = null;

        return;
    }

    let newFilterDate = moment(newValue).locale('nl-BE').format('YYYY-MM-DD');

    filters.value.start_date = new Date(newFilterDate);
});

const filterOnToday = () => {
    // Toggle the filter on today if it is not already set
    filters.value.start_date === today.value ? filters.value.start_date = null : filters.value.start_date = today.value;

};

const deploymentStore = useDeploymentStore();

const bikes = computed<Bike[]>(() => {

    if (filters.value.start_date === null) {
        return deploymentStore.getDeployment?.bicycles || [];
    }

    // If empty return empty array 

    if (!deploymentStore.getDeployment?.bicycles) return [];


    // Filter the bookings on the bicycles
    let filteredBikes = deploymentStore.getDeployment?.bicycles.map((b) => {
        return {
            ...b,
            bookings: b.bookings?.filter((booking) => {
                // Check if the start_date or end_date is the same as the filter date or if the date is between the start and end date
                return (booking.start_date.toDateString() === filters.value.start_date?.toDateString() ||
                    booking.end_date.toDateString() === filters.value.start_date?.toDateString()) ||
                    (booking.start_date <= filters.value.start_date! && booking.end_date >= filters.value.start_date!);
            }),
        };
    });

    // Remove the bikes that have no bookings
    return filteredBikes.filter((b) => b.bookings?.length !== 0);

});

const expandedRows = ref<any>({});

const expandAll = () => {
    expandedRows.value = bikes.value.reduce((acc: any, p: Bike) => (acc[p.id] = true) && acc, {});
};

const collapseAll = () => {
    expandedRows.value = {};
};

const onRowExpand = (e: any) => {
    expandedRows.value = { ...expandedRows.value, [e.data.id]: true };
};

const onRowCollapse = (e: any) => {
    const newRow = { ...expandedRows.value };
    // @ts-ignore
    delete newRow[e.data.id];
    expandedRows.value = newRow;
};

const onRowClick = (e: any) => {
    if (expandedRows.value[e.data.id]) {
        onRowCollapse(e);
    } else {
        onRowExpand(e);
    }
};
const rowClass = (data: any) => {
    // @ts-ignore
    return [{ "[&>td]:border-b-0": expandedRows.value[data.id]}];
};


</script>

<style>
/* For all tables don't show the border for td's in the last row (tr) of the table */
table tr:last-child td {
    border-bottom: none !important;
}

/* No Border for td's in second table */
.no-border td {
    border: none !important;
}
</style>