import { useDeploymentStore } from "@/stores/Deployment/DeploymentStore";
import AxiosClient from "../api";
import { CreateBookingDto, ConfirmPickUpDto, ConfirmDropOffDto } from "./types";

const bookBicycle = async (bikeId: number, Booking: CreateBookingDto) => {
  const deploymentStore = useDeploymentStore();

  const deploymentCode = deploymentStore.getDeployment ? deploymentStore.getDeployment.login_code : undefined;
  if (!deploymentCode) {
    return;
  }
  const response = await AxiosClient.post(
    `/deployments/code/${deploymentCode}/bicycles/${bikeId}/book`,
    Booking
  );
  return response;
};

const confirmPickUp = async (bookingId: number, Booking: ConfirmPickUpDto) => {
  const response = await AxiosClient.post(
    `/bookings/${bookingId}/confirm-pickup`,
    Booking
  );
  return response;
};

const confirmDropOff = async (
  bookingId: number,
  Booking: ConfirmDropOffDto
) => {
  const response = await AxiosClient.post(
    `/bookings/${bookingId}/confirm-dropoff`,
    Booking
  );
  return response;
};

const cancelBooking = async (bookingId: number) => {
  const response = await AxiosClient.put(`/bookings/${bookingId}/cancel`);
  return response;
};

export const BookingService = {
  bookBicycle,
  confirmPickUp,
  confirmDropOff,
  cancelBooking,
};
