<template>
        <div class="flex flex-col px-8 pb-8 gap-4 lg:pt-6 max-w-screen-xl">
        <h1 class="self-start !font-medium">
            {{ $t("testfleet.hr.settings.title") }}
        </h1>
        <div class="flex flex-col divide-y divide-primary-100">
            <div class="space-y-2 py-8">
                <h3 class="font-medium text-lg">
                    {{ $t("testfleet.hr.settings.location") }}
                </h3>
                <p class="font-light text-md" v-html="$t('testfleet.hr.settings.location.description')">
                </p>
                <input name="locatiom" type="text" v-model="location"
                    class="bg-primary bg-opacity-[4%] py-4 px-2 disabled:opacity-50 w-full" data-cy='location' />
                <!-- Input Field -->
            </div>
            <div class="space-y-2 py-8">

                <h3 class="font-medium text-lg">
                    {{ $t("testfleet.hr.settings.period") }}
                </h3>
                <p v-html="$t('testfleet.hr.settings.period.description', { start_date: formatDate(new Date(deploymentStore.getDeployment?.start_date as string), $tolgee.getLanguage()!, 'weekday'), end_date: formatDate(new Date(deploymentStore.getDeployment?.end_date as string), $tolgee.getLanguage()!, 'weekday') })"
                    class="font-light text-md">
                </p>

            </div>
            <div class="space-y-4 py-8">
                <div class="flex flex-col md:flex-row gap-12">
                    <div class="w-full space-y-2">

                        <h3 class="font-medium text-lg">
                            {{ $t("testfleet.hr.settings.timeslots.dropoff") }}
                        </h3>

                        <!-- From - Until -->

                        <div class="flex gap-4">
                            <div class="w-full">

                                <label for="dropoff-from" class="font-light">
                                    {{ $t("testfleet.hr.settings.timeslots.from") }}
                                </label>
                                <input type="time" v-model="newDropoffStart"
                                    class="bg-primary bg-opacity-[4%] py-4 px-2 disabled:opacity-50 w-full"
                                    id="pickup-from" />
                            </div>
                            <div class="w-full">

                                <label for="dropoff-until" class="font-light">
                                    {{ $t("testfleet.hr.settings.timeslots.until") }}
                                </label>
                                <input type="time" v-model="newDropoffEnd"
                                    class="bg-primary bg-opacity-[4%] py-4 px-2 disabled:opacity-50 w-full"
                                    id="dropoff-until" />
                            </div>
                        </div>

                    </div>
                    <!-- Pickup -->
                    <div class="w-full space-y-2">

                        <h3 class="font-medium text-lg">
                            {{ $t("testfleet.hr.settings.timeslots.pickup") }}
                        </h3>

                        <!-- From - Until -->
                        <div class="flex gap-4">
                            <div class="w-full">

                                <label for="pickup-from" class="font-light">
                                    {{ $t("testfleet.hr.settings.timeslots.from") }}
                                </label>
                                <input type="time" v-model="newPickupStart"
                                    class="bg-primary bg-opacity-[4%] py-4 px-2 disabled:opacity-50 w-full"
                                    id="pickup-from" />
                            </div>
                            <div class="w-full">

                                <label for="pickup-until" class="font-light">
                                    {{ $t("testfleet.hr.settings.timeslots.until") }}
                                </label>
                                <input type="time" v-model="newPickupEnd"
                                    class="bg-primary bg-opacity-[4%] py-4 px-2 disabled:opacity-50 w-full"
                                    id="pickup-until" />
                            </div>
                        </div>

                    </div>
                </div>
                <p v-if="error" class="text-error">{{ error as string }}</p>    
                <div v-html="$t('testfleet.hr.settings.timeslots.description')" class="space-y-2 font-light"></div>
            </div>



            <button @click="updateDeployment" class="bg-primary text-white py-3 px-8 disabled:opacity-50 rounded-full w-fit" :disabled="!updateIsEnabled">
                {{ $t("testfleet.hr.settings.save") }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useDeploymentStore } from '@/stores/Deployment/DeploymentStore';
import { formatDate } from '@/utils/date';
import { computed } from 'vue';
import { useTolgee } from '@tolgee/vue';
import {useToast} from 'primevue/usetoast';
// Get deployment store
const deploymentStore = useDeploymentStore();
const $t = useTolgee().value.t;
const toast = useToast();

const newPickupStart= ref<string>(deploymentStore.getDeployment?.pickup_timeslot_start || '');
const newPickupEnd= ref<string>(deploymentStore.getDeployment?.pickup_timeslot_end || '');
const newDropoffStart= ref<string>(deploymentStore.getDeployment?.dropoff_timeslot_start || '');
const newDropoffEnd= ref<string>(deploymentStore.getDeployment?.dropoff_timeslot_end || '');
const error = ref<string | null>(null);

// Reactive location value
const location = ref<string>(deploymentStore.getDeployment?.location || '');

const updateIsEnabled = computed(() => {

    let isUpdated = newPickupStart.value !== deploymentStore.getDeployment?.pickup_timeslot_start ||
        newPickupEnd.value !== deploymentStore.getDeployment?.pickup_timeslot_end ||
        newDropoffStart.value !== deploymentStore.getDeployment?.dropoff_timeslot_start ||
        newDropoffEnd.value !== deploymentStore.getDeployment?.dropoff_timeslot_end ||
        location.value !== deploymentStore.getDeployment?.location;

    // Format HH:MM
    let pickupTimeSlotsAreValid = newPickupStart.value < newPickupEnd.value;
    let dropoffTimeSlotsAreValid = newDropoffStart.value < newDropoffEnd.value;

    if (!pickupTimeSlotsAreValid || !dropoffTimeSlotsAreValid) {
        error.value = $t('testfleet.hr.settings.timeslots.error');
    } else {
        error.value = null;
    }

    
    return isUpdated && pickupTimeSlotsAreValid && dropoffTimeSlotsAreValid;
});


const updateDeployment = async () => {
    try {
        await deploymentStore.updateDeployment({
            pickup_timeslot_start: newPickupStart.value,
            pickup_timeslot_end: newPickupEnd.value,
            dropoff_timeslot_start: newDropoffStart.value,
            dropoff_timeslot_end: newDropoffEnd.value,
            location: location.value
        });

        toast.add({severity:'success', summary: $t('testfleet.hr.settings.success'), life: 3000});
    } catch (error) {
        console.error('Failed to update timeslots:', error);
    }
};

</script>
