import { Tolgee, DevTools, BackendFetch } from "@tolgee/vue"
import { FormatIcu } from "@tolgee/format-icu"

export const tolgee = Tolgee()
  .use(DevTools()) // Allows us to fetch translations from Tolgee API and Edit then -> Automaticaly removed in production
  .use(BackendFetch({prefix: 'https://cdn.tolg.ee/52d35785ff6d2ac0bde6e16d6a2a3976'})) // CDN Translations
  .use(FormatIcu()) // Allows formatting e.g. 'Hello {name}' -> f('Hello {name}', {name: 'John'})
  .init({
    language: 'nl-BE', // Default language
        
    // static data (for now), in future it will be fetched from CDN
    staticData: {
      nlBE: () => import('./nl.json'),
    },
        
    apiUrl: import.meta.env.VITE_TOLGEE_API_URL, // for production
    apiKey: import.meta.env.VITE_TOLGEE_API_KEY, // for production
  })