<template>
  <Card :pt="{
    root: {
      class: 'border-primary-100 border-2 bg-primary-50 rounded-lg flex',
    },
    header: {
      class: 'w-1/3 bg-white flex items-center justify-center',
    },
  }">
    <template #header>

      <Image :alt="props.brand + ' ' + props.model" preview>
        <template #image>

          <img class="rounded-s-lg contain" :src="props.image_url" :alt="props.brand + ' ' + props.model" />
        </template>
        <template #preview="slotProps">

          <img :src="props.image_url" alt="preview" :style="slotProps.style" @click="slotProps.onClick" class="max-w-[200px] max-w[200px] md:max-w-[400px] md:max-h-[400px] lg:max-w-[600px] lg:max-h-[600px]" />
        </template>
      </Image>
    </template>

    <template #content>
      <div class="flex flex-col px-4 justify-center py-2.5" @click="$emit('cardClicked')">
        <span class="text-xs leading-6 tracking-wider uppercase">
          {{ $t("testfleet.yourChoice") }}
        </span>
        <h3>{{ props.brand }} {{ props.model }}</h3>
      </div>
    </template>
  </Card>

</template>

<script setup lang="ts">
import Card from "primevue/card";
import Image from "primevue/image";

const props = defineProps<{
  brand: string;
  model: string;
  image_url: string;
}>();
</script>