import { defineStore } from "pinia";
import { CreateBookingDto, Booking } from "../../services/booking/types";
import { useBikeStore } from "../Bike/BikeStore";
import { BookingService } from "../../services/booking";
import { useUserStore } from "../User/UserStore";

interface BookingState {
    booking: Booking | null,
}


export const useBookingStore = defineStore("BookingStore", {
  state: (): BookingState => ({
    booking: null,
  }),
  getters: {
    getBooking(state) {
      return state.booking;
    }
  },
  actions: {
    async bookBike(booking: CreateBookingDto) {
      // Send Booking to API
      const bikeStore = useBikeStore();
      const bike = bikeStore.getCurrentBike;
      if (!bike) throw new Error("Bike not found");
      const response = await BookingService.bookBicycle(bike.id, booking);
      // Set the booking object in the store
      this.booking = response?.data.booking;
      // Fetch the user to update the bookings
      await useUserStore().fetchUser();
      return booking;
    },
    async confirmPickUp(bookingId: number, images: any) {
      // Send Booking to API
      const response = await BookingService.confirmPickUp(bookingId, images);
      // Fetch the user to update the bookings
      await useUserStore().fetchUser();
      return response;
    },
    async confirmDropOff(bookingId: number, images: any) {
      // Send Booking to API
      const response = await BookingService.confirmDropOff(bookingId, images);
        // Fetch the user to update the bookings
        await useUserStore().fetchUser();
      return response;
    },
    async cancelBooking(bookingId: number) {
      // Send Booking to API
      const response = await BookingService.cancelBooking(bookingId);
      await useUserStore().fetchUser();
      return response;
    }
  }
});
