<template>
    <div :class="[{ 'lg:grid lg:grid-cols-2': pickupVisible, 'lg:grid-cols-1': !pickupVisible }, 
                 'flex flex-col lg:flex-1 border border-primary-100 divide-y divide-primary-100 lg:divide-y-0 rounded-lg text-sm']">
        <!-- Pickup -->
        <div v-if="pickupVisible" :class="[pickupVisible ? 'lg:border-e lg:border-primary-100' : '']" class="p-8 flex flex-col items-center gap-2 w-full">
            <div class="flex gap-2 items-center">
                <div class="bg-tertiary rounded-full p-1 flex items-center justify-center">
                    <CalendarClock class="text-white" :size="16" />
                </div>
                <h2>
                    {{ $t("testfleet.booking.reserve.pickup.title") }}
                </h2>
            </div>
            <div class="space-y-1 text-center font-roboto">
                <p class="capitalize">
                    {{ formatDate(new Date(booking.start_date), $tolgee.getLanguage() as string, 'long') }}
                </p>
                <p class="capitalize">
                    {{ deploymentStore.getPickupTime }}
                </p>
            </div>
        </div>
        <!-- Dropoff -->
        <div :class="[pickupVisible ? '' : 'lg:col-span-1']" class="p-8 flex flex-col items-center gap-2">
            <div class="flex gap-2 items-center">
                <div class="bg-tertiary rounded-full p-1 flex items-center justify-center">
                    <CalendarClock class="text-white" :size="16" />
                </div>
                <h2>
                    {{ $t("testfleet.booking.reserve.dropoff.title") }}
                </h2>
            </div>
            <div class="space-y-1 text-center font-roboto">
                <p class="capitalize">
                    {{ formatDate(new Date(booking.end_date), $tolgee.getLanguage() as string, 'long') }}
                </p>
                <p class="capitalize">
                    {{ deploymentStore.getDropoffTime }}
                </p>
            </div>
        </div>
        <!-- Location -->
        <div v-if="location" class="p-8 flex flex-col items-center gap-2 lg:col-span-2 lg:!border-t border-primary-100">
            <div class="flex gap-2 items-center">
                <div class="bg-tertiary rounded-full p-1 flex items-center justify-center">
                    <MapMarker class="text-white" :size="16" />
                </div>
                <h2>
                    {{ $t("testfleet.booking.reserve.location.title") }}
                </h2>
            </div>
            <div class="space-y-1 text-center">
                <p>
                    {{ location }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
//@ts-ignore
import CalendarClock from 'vue-material-design-icons/CalendarClock.vue';
//@ts-ignore
import MapMarker from 'vue-material-design-icons/MapMarker.vue';
import { Booking, BookingStatus } from '@/services/booking/types';
import { PropType } from 'vue';
import { formatDate } from '@/utils/date';
import { useDeploymentStore } from '@/stores/Deployment/DeploymentStore';
const { booking, location, bookingStatus } = defineProps({
  booking: {
    type: Object as PropType<Booking>,
    required: true
  },
  location: {
    type: String,
    required: false
  },
  bookingStatus: {
    type: Object as PropType<BookingStatus>,
    required: false
  }
});

const deploymentStore = useDeploymentStore();
const pickupVisible = bookingStatus !== BookingStatus.PickedUp;
</script>