<template>
    <div class="flex flex-col gap-2">
        <!-- Title -->
        <div class="flex gap-2 items-center">
            <div class="bg-tertiary rounded-full p-1 flex items-center justify-center">
                <MapMarker class="text-white" :size="16" />
            </div>
            <h2>
                {{ $t("testfleet.booking.reserve.location.title") }}
            </h2>
        </div>
        <p>
            {{ props.location }}
        </p>
    </div>
</template>

<script setup lang="ts">
//@ts-ignore
  import MapMarker from 'vue-material-design-icons/MapMarker.vue'

  const props = defineProps({
    location: {
      type: String,
      required: true
    }
  })

</script>
