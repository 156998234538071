export default {
  root: {
    class: [
      "rounded-xl panel-shadow",
      "border mt-1",
      "z-40 transform origin-center",
      "bg-surface-0",
      "text-surface-700",
    ],
  },
  content: {
    class: "p-0 items-center flex",
  },
  transition: {
    enterFromClass: "opacity-0 scale-y-[0.8]",
    enterActiveClass:
      "transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]",
    leaveActiveClass: "transition-opacity duration-100 ease-linear",
    leaveToClass: "opacity-0",
  },
};
