import { Bike, TimeSlot } from "../bikes/types";
import { Deployment } from "../deployment/types";
import { Remark } from "../remark/types";
import { User } from "../user/types";

export enum BookingStatus {
  Confirmed = 'confirmed',
  PickedUp = 'picked-up',
  Cancelled = 'cancelled',
  DroppedOff = 'dropped-off',
}

export type CreateBookingDto = {
    user: {
        first_name: string;
        last_name: string;
        email: string;
        birth_date?: string;
    };
    slot: {
        pickup: TimeSlot;
        dropoff: TimeSlot;
    }
}

export type ConfirmPickUpDto = {
  damage_description: string;
  images: string[];
}
export type ConfirmDropOffDto = {
  damage_description: string;
  images: string[];
}

export type Booking = {
    id: number;
    start_date : Date;
    end_date :  Date;
    deployment_bicycle_id : number;
    bicycle?: Bike;
    deployment: Deployment;
    status: BookingStatus;
    admin_pickup_confirmed?: boolean;
    admin_dropoff_confirmed?: boolean;
    picked_up_helmet: boolean;
    user?: User;
    remarks: Remark[];
}