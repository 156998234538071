import { AuthType } from "@/router/AuthGuard";
import { AuthResponseDto } from "../auth/types";
import { jwtDecode } from "jwt-decode";

const AUTH_TYPE_KEY = "auth_type";
const TOKEN_KEY = "token";
const DEPLOYMENT_CODE_KEY = "deployment_code";

const setJwtToken = (token: AuthResponseDto) => {
  // Save token to localstorage
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  localStorage.setItem(AUTH_TYPE_KEY, "jwt_token");
};

const getJwtToken = (): AuthResponseDto | null => {
  // Get token from localstorage
  const token = localStorage.getItem(TOKEN_KEY);
  const parsedToken = token ? (JSON.parse(token) as AuthResponseDto) : null;
  // If token does not exist, return null
  if (!parsedToken) {
    return null;
  }
  // Parse token to object
  return parsedToken as AuthResponseDto;
};

const handleRename = () => {
  // We used to use 'fleet_code' as the auth type, but now we use 'deployment_code' to be consistent with the backend
  // Some users may still have 'fleet_code' in their local storage, so we check if 'fleet_code' is set and if so we clear localstorage completely
  if (localStorage.getItem("fleet_code")) {
    localStorage.clear();
  }
  if (localStorage.getItem("auth_type") === "fleet_code") {
    localStorage.clear();
  }
};

const clearJwtToken = () => {
  // Remove token from localstorage
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(AUTH_TYPE_KEY);
};

const clearDeploymentCode = () => {
  // Remove token from localstorage
  localStorage.removeItem(DEPLOYMENT_CODE_KEY);
};

const clearAuthType = () => {
  // Remove token from localstorage
  localStorage.removeItem(AUTH_TYPE_KEY);
};

const clearLocalStorage = () => {
  // Remove token from localstorage
  clearJwtToken();
  clearDeploymentCode();
  clearAuthType();
};

const setDeploymentCode = (code: string) => {
  // Save token to localstorage
  localStorage.setItem(DEPLOYMENT_CODE_KEY, code);
};

const getDeploymentCode = () => {
  // Save token to localstorage
  return localStorage.getItem(DEPLOYMENT_CODE_KEY);
};

const setAuthType = (authType: string) => {
  // Save token to localstorage
  localStorage.setItem(AUTH_TYPE_KEY, authType);
};

const getAuthType = () => {
  const authType = localStorage.getItem(AUTH_TYPE_KEY);
  if (!authType) {
    setAuthType(AuthType.PUBLIC);
    return AuthType.PUBLIC;
  }
  return authType;
};
const getJWTPayload = () => {
  const token = getJwtToken();
  if (!token) {
    return null;
  }
  return jwtDecode(token.access_token) as { [key: string]: any };
};

export const LocalStorageService = {
  setJwtToken,
  getJwtToken,
  clearJwtToken,
  getDeploymentCode,
  setDeploymentCode,
  setAuthType,
  getAuthType,
  handleRename,
  clearLocalStorage,
  clearDeploymentCode,
  clearAuthType,
  getJWTPayload,
};
