export default {
  root: {
    class: 'bg-primary-50 rounded-xl border-2 border-primary-100 lg:flex lg:flex-row',
  },
  body: {
    class: 'lg:flex lg:flex-row lg:flex-col lg:flex-1 lg:border-l-2 lg:border-primary-100',
  },
  header: {
    class:
            'bg-red-200 flex lg:justify-start items-center justify-center h-[250px] min-h-[250px] max-h-[250px] lg:max-h-[100%] lg:!h-inherit lg:min-h-[100%] overflow-hidden rounded-t-xl flex-1 lg:flex-none border-b-2 border-primary-100 relative lg:border-0',
  },
  title: {
    class:
            'py-2 bike-card-title flex flex-row justify-between items-start w-full py-2 px-5 pt-2.5',
  },
  content: {
    class: 'font-roboto lg:flex flex-col lg:flex-1',
  },
}