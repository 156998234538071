<template>
  <Card :pt="BikeCardPreset" :id="'bike-card-' + bike.id" v-if="!bike.inactive">
    <template #header>
      <BikeCode :bicycleCode="bike.bicycle_code" class="absolute bottom-[0.5rem] left-[0.5rem] z-10" />
      <Image :alt="bike.brand + ' ' + bike.model" preview class="w-[12rem]">
        <template #image>
          <img class="p-4 lg:p-1" :src="bike.image_url" :alt="bike.brand + ' ' + bike.model" />
        </template>
        <template #preview="slotProps">
          <img :src="bike.image_url" alt="preview" :style="slotProps.style" @click="slotProps.onClick" class="max-w-[200px] max-w[200px] md:max-w-[400px] md:max-h-[400px] lg:max-w-[600px] lg:max-h-[600px]" />
        </template>
      </Image>
    </template>
    <template #title>
      <h2 @click="onCardClick">{{ bike.brand }} {{ bike.model }}</h2>
      <router-link :to="'/book/' + bike.id" custom v-slot="{ navigate }">
        <button @click="navigate" role="link" class="rounded-full px-2 py-2 bg-primary lg:hidden">
          <arrow-right :size="16" fillColor="white" />
        </button>
      </router-link>
    </template>
    <template #content>
      <div @click="onCardClick" class="content-class" :data-cy="'bike-card-' + bike.id + '-details'">
        <p class="text-sm font-regular">
          {{ $t("testfleet.bike.attributes.propulsion.label") }}:
          <span class="font-medium">
            {{ $t("testfleet.bike.attributes.propulsion." + bike.propulsion) }}
          </span>
        </p>
        <p class="text-sm font-regular">
          {{ $t("testfleet.bike.attributes.modelType.label") }}:
          <span class="font-medium">
            {{ $t("testfleet.bike.attributes.modelType." + bike.frame_type) }}
          </span>
        </p>
        <p class="text-sm font-regular" v-if="bike.range">
          {{ $t("testfleet.bike.attributes.range") }}:
          <span class="font-medium">
            {{ bike.range + " km" }}
          </span>
        </p>
        <p class="text-sm font-regular" v-if="lease_price">
          {{ $t("testfleet.bike.attributes.lease_price.label") }}:
          <span v-if="bike.lease_price" v-html="lease_price" class="font-medium" /> *
        </p>
      </div>
      <router-link :to="'/book/' + bike.id" custom v-slot="{ navigate }">
        <button @click="navigate" role="link"
          class="rounded-full p-3 w-8 h-8 bg-primary hidden lg:flex lg:items-center lg:justify-center">
          <arrow-right :size="16" fillColor="white" />
        </button>
      </router-link>
    </template>
  </Card>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { Bike } from "@/services/bikes/types";
//@ts-ignore
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";
import { PropType, computed } from "vue";
import Card from "primevue/card";
import BikeCardPreset from '@/presets/o2o/BikeCard'
import { getCurrentLocaleValue } from "@/utils/i18n";
import { useTolgee } from "@tolgee/vue";
import BikeCode from "../BikeCode/BikeCode.vue";
import Image from "primevue/image";
const router = useRouter();

const { bike } = defineProps({
  bike: {
    type: Object as PropType<Bike>,
    required: true,
  },
});

const onCardClick = () => {
  router.push("/book/" + bike.id);
};

const lease_price = computed(() => {
  // Strip any html tags from the lease price only taking the value
  return getCurrentLocaleValue(
    bike.lease_price!,
    useTolgee().value.getLanguage()
  )
    ?.replace(/<[^>]*>?/gm, "")
    ?.trim();
});
</script>