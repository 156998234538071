export default {
  root: ({ props }: { props: any }) => ({
    class: [
      "overflow-hidden",
      {
        "animate-pulse": props.animation !== "none",
      },
      // Round
      {
        "rounded-full": props.shape === "circle",
        "rounded-md": props.shape !== "circle",
      },
      // Colors
      "bg-primary-100 dark:bg-gray-700",
    ],
  }),
};
