<template>
  <v-calendar expanded
:rows="spansMultipleMonths ? 2 : 1"
:attributes="attrs"
disabled
:min-date="startDate"
    :max-date="endDate"
:locale="$tolgee.getLanguage()" />
</template>

<script setup lang="ts">
  import { PropType, ref } from "vue";
  import { Availability } from "@/services/bikes/types";

  const { availabilities } = defineProps({
    availabilities: {
      type: Object as PropType<Availability>,
      required: true,
    },
  });

  const startDate = new Date(availabilities?.start_date);
  const endDate = new Date(availabilities?.end_date);

  const spansMultipleMonths = startDate.getMonth() !== endDate.getMonth();

  const attrs = ref<Array<{}>>([]);

  // for each day, create a layer with the start and end date
  availabilities?.days.forEach((day) => {
    attrs.value.push({
      key: 'days',
      order: 1,
      highlight: {
        fillMode: 'light',
        style: {
          // transparent background
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        class: 'border-b-[5px] border-success-300 !rounded-none'
      },
      content: {
        class: '!font-normal !text-neutral-700 pb-1'
      },
      dates: [
        {
          start: new Date(day.pickup.date),
          end: new Date(new Date(day.pickup.date).setDate(new Date(day.pickup.date).getDate() + (new Date(day.pickup.date).getDay() == 5 ? 3 : 2))),
        }
      ],
    });

  });


  attrs.value.push({
    key: 'base',
    highlight: {
      class: 'border-b-[5px] border-error-300 !rounded-none',
      fillMode: 'light',
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
    content: {
      class: '!font-normal !text-neutral-700 pb-1'
    },
    dates: [
      {
        start: startDate,
        end: endDate,
      },
    ],
  },)
</script>