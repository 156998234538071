<template>
    <form-step>
        <form-layout class="col-span-2 h-full">
            <template v-slot:title>
                {{ $t("testfleet.booking.personalDetail.title") }}
            </template>

            <template v-slot:description>
                {{ $t("testfleet.booking.personalDetail.description") }}
            </template>

            <template v-slot:form>
                <div class="flex flex-col gap-6 pb-16 lg:pb-0 px-8">
                    <div class="flex flex-col gap-1.5">
                        <label for="user.first_name" class="font-semibold font-roboto text-md">
                            {{ $t("testfleet.booking.personalDetail.firstName") }}
                        </label>
                        <Field name="user.first_name" type="text" class="bg-primary bg-opacity-[4%] py-4 px-2 disabled:opacity-50" :disabled="user"
                            data-cy='first-name' />
                        <ErrorMessage name="user.first_name" class="text-error-500" />
                    </div>
                    <div class="flex flex-col gap-1.5">
                        <label for="user.last_name" class="font-semibold font-roboto text-md">
                            {{ $t("testfleet.booking.personalDetail.lastName") }}
                        </label>
                        <Field name="user.last_name" type="text" class="bg-primary bg-opacity-[4%] py-4 px-2 disabled:opacity-50" :disabled="user"
                            data-cy='last-name' />
                        <ErrorMessage name="user.last_name" class="text-error-500" />
                    </div>
                    <div class="flex flex-col gap-1.5">
                        <label for="user.email" class="font-semibold font-roboto text-md">
                            {{ $t("testfleet.booking.personalDetail.email") }}
                        </label>
                        <span class="font-roboto text-neutral-700 text-sm">
                            {{
                    user ?
                        $t("testfleet.booking.personalDetail.emailDescriptionAuthenticated")
                        :
                        $t("testfleet.booking.personalDetail.emailDescription") }}
                        </span>
                        <Field name="user.email" type="text"
                            class="bg-primary bg-opacity-[4%] py-4 px-2 disabled:opacity-50"
                            :disabled="user" data-cy='email' />
                        <ErrorMessage name="user.email" class="text-error-500" />
                    </div>
                    <div class="flex flex-col gap-1.5" v-if="bikeType === 'speed_pedelec'">
                        <label for="user.birth_date" class="font-semibold font-roboto text-md">
                            {{ $t("testfleet.booking.personalDetail.dob") }}
                        </label>
                        <Field name="user.birth_date" rules="required" v-slot="{ field }">
                            <VDatePicker expanded v-bind="field" mode="date" :locale="$tolgee.getLanguage()">
                                <template #default="{ inputValue, inputEvents }">
                                    <input type="text" placeholder="dd/mm/yyyy" pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                                        class="bg-primary bg-opacity-[4%] py-4 px-2" :value="inputValue"
                                        v-on="inputEvents" data-cy='birth_date' />
                                </template>
                            </VDatePicker>
                        </Field>
                        <ErrorMessage name="user.birth_date" class="text-error-500" />
                    </div>
                </div>
            </template>
        </form-layout>
    </form-step>
</template>

<script setup lang="ts">
import FormStep from '@/components/Form/FormStep.vue'
import FormLayout from '@/components/Form/FormLayout.vue'
import { Field, ErrorMessage, useField } from 'vee-validate';
import { PropType } from 'vue';
import { User } from '@/services/user/types';

const { bikeType, user } = defineProps({
    bikeType: {
        type: String,
        required: true
    },
    user: {
        type: Object as PropType<User>,
        required: false
    }
})

// If user is passed prefill the form
const firstNameField = useField('user.first_name');
const lastNameField = useField('user.last_name');
const emailField = useField('user.email');


if (user) {
    firstNameField.setValue(user.name);
    lastNameField.setValue(user.last_name);
    emailField.setValue(user.email);
}

</script>
