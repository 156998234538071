<template>
    <FileUpload :multiple="true" accept="image/*" :maxFileSize="10485760" name="files[]" :file-limit="5"
        :invalid-file-limit-message="$t('testfleet.booked.pickup.steps.pictures.upload.limit')"
        :invalid-file-size-message="$t('testfleet.booked.pickup.steps.pictures.upload.size', {file: '{0}', size: '{1}'})"
        :custom-upload="() => {return true}">
        <template #header="{ chooseCallback }">
            <div class="hidden">
                <button @click="chooseCallback()" class="hidden" ref="uploadButton"></button>
            </div>
        </template>
        <template #content="{ files, removeFileCallback, messages }" @click="">
            <ul class="flex flex-col gap-1.5 mb-4">
                <li v-for="(message, index) in messages" :key="'message' + index"
                    class="text-error-500 py-4 px-4 roundec border border-error-500 rounded-xl inline-flex justify-between">
                    {{ message }}
                    <!-- Remove  -->
                    <button @click="() => {
        // @ts-ignore
        messages?.splice(index, 1)
    }" class="w-6 h-6 flex items-center justify-center">
                        <TrashCanOutline :size="24" />
                    </button>
                </li>

                <li v-if="files.length > 0 && files.length < 2"
                    class="text-error-500 py-4 px-4 rounded border border-error-500 rounded-xl inline-flex justify-between">
                    {{ $t('testfleet.booked.pickup.steps.pictures.upload.min') }}
                </li>


            </ul>
            <div class="flex flex-col gap-1.5">
                <div v-if="files.length > 0">

                    <div class="flex flex-wrap p-0 gap-5">
                        <div v-for="(file, index) of files" :key="file.name + file.type + file.size"
                            class="card m-0 flex flex-col border-2 border-primary-100 rounded-lg items-center gap-3">
                            <div class="relative">

                                <button @click="removeFileCallback(index)"
                                    class="absolute top-0 right-0 w-6 h-6 flex items-center justify-center text-white bg-neutral-900 bg-opacity-70">
                                    <TrashCanOutline :size="20" />
                                </button>
                                <img role="presentation" :alt="file.name" class="rounded-lg lg:max-h-48 lg:max-w-48 lg:w-48 lg:h-48 w-24 h-24 object-contain" :src="getFilePreview(file)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-primary-50 px-10 px-10 py-6 rounded-md space-y-2 cursor-pointer"
                    @click="handleFileUploadClick">

                    <div class="flex flex-col gap-2 items-center">
                        <ImagePlusOutline :size="40" />
                        <span class="text-center">
                            {{ $t("testfleet.booked.pickup.steps.pictures.upload.empty")
                            }}
                        </span>
                    </div>
                </div>
            </div>

        </template>
    </FileUpload>

</template>

<script setup lang="ts">
import { ref } from 'vue';
//@ts-ignore
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
//@ts-ignore
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue'
//@ts-ignore
import ImagePlusOutline from 'vue-material-design-icons/ImagePlusOutline.vue'
//@ts-ignore
import TrashCanOutline from 'vue-material-design-icons/TrashCanOutline.vue'


import FileUpload from 'primevue/fileupload';

const uploadButton = ref<HTMLButtonElement | null>(null);

const getFilePreview = (file: any) => {
    return URL.createObjectURL(file);
};

const handleFileUploadClick = () => {
    uploadButton.value?.click();
};

</script>
