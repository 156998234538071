<template>
    <div class="flex justify-center py-4 flex-1">
        <div class="flex flex-col rounded-lg gap-8 px-2">
            <form-layout class="col-span-2 h-full">
                <template v-slot:title>
                    {{ $t("testfleet.booked.cancel.title") }}
                </template>

                <template v-slot:description>

                    <span
                        v-html="$t('testfleet.booked.cancel.description', { bike: booking?.bicycle?.brand + ' ' + booking?.bicycle?.model })"></span>
                </template>
                <template v-slot:form>
                    <div class="space-y-10 px-8" v-if="booking">

                        <ReservationDetail :location="booking.deployment.location" :booking="booking"
                            :booking-status="booking.status"></ReservationDetail>

                        <div class="flex flex-col space-y-10">

                            <!-- Buttons -->
                            <div class="flex gap-3">
                                <Button
                                variant="ghost"
                                type="button" @click="() => router.push({ path: '/my-bookings' })"
                                class="ps-4 pe-6 py-3 gap-2 text-sm flex items-center justify-center"
                                iconLeft="ArrowLeft"
                                :label="$t('testfleet.booking.form.button.back')"
                                :disabled="loading" />
                                <Button
                                data-cy="next-btn"
                                @click="cancelBooking"
                                class="ps-4 pe-6 py-3 gap-2 text-nowrap text-sm flex items-center justify-center w-full"
                                :label="$t('testfleet.booked.cancel.submit')"
                                :loading="loading"
                                />
                            </div>
                        </div>
                    </div>
                </template>
            </form-layout>
        </div>
    </div>
</template>

<script setup lang="ts">
import FormLayout from '../../components/Form/FormLayout.vue'
import { useUserStore } from '../../stores/User/UserStore';
import { storeToRefs } from 'pinia';
import ReservationDetail from '../../components/Booking/ReservationDetail/ReservationDetail.vue';
import { computed } from 'vue';
import Button from '@/components/Button/Button.vue';
import { useRoute, useRouter } from 'vue-router';
import { useBookingStore } from '@/stores/Booking/BookingStore';
import { ref } from 'vue';

const route = useRoute();
const router = useRouter();

const { user } = storeToRefs(useUserStore());
const bookingId = Number(route.params.id);
const booking = computed(() => user.value?.bookings.find(b => b.id === bookingId));

const loading = ref(false);

const cancelBooking = async () => {
    loading.value = true;
    if (booking.value) {
        await useBookingStore().cancelBooking(bookingId);
        router.push({ path: '/' });
    }
}

</script>
