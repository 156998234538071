<template>
    <div>
        <button
            class="flex items-center gap-2 text-sm font-medium px-4 py-2 border border-success text-success rounded-full enabled:hover:bg-success enabled:hover:text-white font-light w-full disabled:opacity-50"
            :class="{ 'bg-success text-white cursor-default': booking.admin_dropoff_confirmed}"
            :disabled="!booking.admin_pickup_confirmed"
            v-if="booking.status !== BookingStatus.Cancelled && !booking.admin_dropoff_confirmed"
            @click="handleDropoffClick">
            <Check :size="20" />

            <!-- Conditional Button message -->
            <span v-if="booking.admin_dropoff_confirmed">
                {{ $t('testfleet.hr.calendar.button.droppedoff') }}
            </span>
            <span v-else>
                {{ $t('testfleet.hr.calendar.button.confirm.dropoff') }}
            </span>
        </button>

        <Dialog v-model:visible="visible" modal :header="$t('testfleet.hr.calendar.dropoff.helmet.title')"
            :draggable="false" :style="{ width: '32rem' }">
            <div class="flex flex-col gap-2">
                <span class="text-md" v-html="$t('testfleet.hr.calendar.dropoff.helmet.description', {name: booking.user?.name + ' ' + booking.user?.last_name})">
                </span>
            </div>
            <div class="flex justify-end gap-2 mt-5">
                <!-- No helmet provided -->
                <button
                    class="flex items-center gap-2 text-sm font-medium px-4 py-2 border border-success text-success rounded-full enabled:hover:bg-success enabled:hover:text-white font-light disabled:opacity-50"
                    @click="dropoff">
                    <span>{{ $t('testfleet.hr.calendar.button.confirm.dropoff') }}</span>
                </button>
                <!-- Helmet provided button -->
            </div>
        </Dialog>
    </div>

</template>

<script setup lang="ts">
import { Booking, BookingStatus } from '@/services/booking/types';
import { useDeploymentStore } from '@/stores/Deployment/DeploymentStore';
import { ref } from 'vue';
import Dialog from "primevue/dialog";


const visible = ref(false);
const deploymentStore = useDeploymentStore();

const { booking } = defineProps<{
    booking: Booking,
}>();


const handleDropoffClick = () => {
    // Show the dialog if the helmet is picked up
    if (booking.picked_up_helmet) {
        visible.value = true;
    } else {
        // If the helmet is not picked up, confirm the dropoff
        deploymentStore.confirmHRDropoff(booking.id);
    }
}

const dropoff = async () => {
    await deploymentStore.confirmHRDropoff(booking.id);
    visible.value = false;
}

</script>
