export default {
    root: {
      class: 'bg-primary-50 rounded-xl border-2 border-primary-100',
    },
    body: {
      class: 'px-5 py-2.5'
    },
    header: {
      class:
              'bg-white flex items-center justify-center h-[250px] min-h-[250px] max-h-[250px] overflow-hidden rounded-t-xl flex-1 relative',
    },
    title: {
      class:
              'py-2 bike-card-title flex flex-row justify-between items-start w-full py-2',
    },
    content: {
      class: 'pb-2 font-roboto',
    },
  }