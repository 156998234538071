<template>
  <div class="flex flex-col lg:flex-row px-8 pb-8 gap-4 lg:pt-6 lg:gap-16 xl:gap-24 mx-auto max-w-screen-xl">
    <CompanyIntro :company_name="deploymentStore.getDeployment?.company_name" class="hidden lg:block" />
    <div class="flex flex-col gap-4 lg:gap-8">
      <KeepAlive>
        <img v-if="deploymentStore.getDeployment?.company_logo_url" class="h-16 self-center lg:self-start aspect-auto"
          :src="deploymentStore.getDeployment?.company_logo_url" alt="logo" />
      </KeepAlive>

      <CompanyIntro :company_name="deploymentStore.getDeployment?.company_name" class="block lg:hidden" />

      <p class="font-light text-sm flex flex-col gap-3 leading-6"
        v-html="$t('testfleet.overview.intro.description', { weeks: deploymentDuration })">
      </p>
      <div class="flex flex-col gap-3">
        <button data-cy="reserve-bike-button" @click="() => router.push({path: '/book'})" class="flex justify-between items-center gap-2.5 p-5 text-[22px] rounded-2xl font-semibold w-full text-start bg-primary text-white">
          <span class="flex flex-col gap-1">
            <span class="text-lg font-medium">
              {{ $t("testfleet.booked.buttons.reserveBike") }}
            </span>
            <span class="text-xs font-light">
              {{ $t("testfleet.booked.buttons.reserveBike.subtitle", {count: bicycleCount}) }}
            </span>
          </span>
          <span class="flex justify-center items-center rounded-full w-8 h-8 bg-white text-primary">
            <arrow-right :size="16" />
          </span>
        </button>
        <button 
          v-if="user"
        data-cy="my-reservations-button" @click="() => router.push({path: '/my-bookings'})" class="flex justify-between items-center gap-2.5 p-5 text-[22px] rounded-2xl font-semibold w-full text-start bg-tertiary text-white">
          <span class="flex flex-col gap-1">
            <span class="text-lg font-medium">
              {{ $t("testfleet.booked.buttons.my-reservations") }}
            </span>
            <span class="text-xs font-light">
              {{ $t("testfleet.booked.buttons.my-reservations.description", {count: bookingCount}) }}
            </span>
          </span>
          <span class="flex justify-center items-center rounded-full w-8 h-8 bg-white text-primary">
            <arrow-right :size="16" />
          </span>
        </button>
        <a class="text-primary font-medium px-2 hover:underline mt-4" href="https://o2o.be" target="_blank">
          {{ $t("testfleet.booked.buttons.o2o") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
// @ts-ignore
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import { useDeploymentStore } from '@/stores/Deployment/DeploymentStore';
import { computed } from 'vue';
import CompanyIntro from '../Deployment/CompanyIntro.vue';
import { useUserStore } from '@/stores/User/UserStore';
import { BookingStatus } from '@/services/booking/types';


const router = useRouter();
const user = computed(() => useUserStore().getUser);

const bicycleCount = computed(() => {
  const bikes = useDeploymentStore().getDeployment?.bicycles.filter(bike => !bike.inactive);
  return bikes ? bikes.length : 0;
});

const deploymentStore = useDeploymentStore();
const bookingCount = computed(() => {
    return user.value?.bookings?.filter(booking => [BookingStatus.Confirmed, BookingStatus.PickedUp].includes(booking.status)).length;
});

const deploymentDuration = computed(() => {
  if (deploymentStore.getDeployment) {
    let startDate = new Date(deploymentStore.getDeployment.start_date);
    let endDate = new Date(deploymentStore.getDeployment.end_date);
    let diffWeeks = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24 * 7));
    return diffWeeks;
  }
  return 0;
});
</script>
