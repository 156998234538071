<template>
    <div class="card flex justify-center">
        <Button @click="visible = true"
            class="bg-primary text-white px-4 py-3 text-sm flex items-center gap-2 z-[1] rounded-full justify-between shadow">
            <Link :size="20" />
            <span class="">

                {{ $t('testfleet.hr.nav.link.button') }}
            </span>

        </Button>
        <Dialog v-model:visible="visible" modal :header="$t('testfleet.hr.nav.link.title')" :draggable="false"
            :dismissableMask="true">
            <div class="flex items-center gap-3 mb-3 bg-primary-50 py-4 px-4 justify-between">
                {{ deploymentLink }}
                <Button
                    class="bg-primary text-white px-4 py-3 text-sm flex items-center gap-2 z-[1] rounded-full justify-between shadow self-end"
                    @click="copyLink">
                    <ContentCopy :size="16" v-if="!linkCopied" />
                    <Check :size="16" v-else />
                    {{ !linkCopied ? $t('testfleet.hr.nav.link.copy.button') :
            $t('testfleet.hr.nav.link.copy.button.success') }}
                </Button>
            </div>

        </Dialog>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
// @ts-ignore
import Link from "vue-material-design-icons/Link.vue";
// @ts-ignore
import ContentCopy from "vue-material-design-icons/ContentCopy.vue";
// @ts-ignore
import Check from "vue-material-design-icons/Check.vue";
import { useDeploymentStore } from "@/stores/Deployment/DeploymentStore";
import { computed } from "vue";
import { watch } from "vue";
const visible = ref(false);

const linkCopied = ref(false);


watch(visible, (newValue) => {
    if (newValue) {
        linkCopied.value = false;
    }
});

const deploymentLink = computed(() => {
    return useDeploymentStore().getDeploymentLink();
});

const copyLink = () => {
    navigator.clipboard.writeText(deploymentLink.value);
    linkCopied.value = true;
};

</script>