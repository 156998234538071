<template>
    <div class="flex py-4 justify-center flex-1 max-w-screen-2xl w-screen md:px-8">
        <div class="flex flex-col lg:flex-row rounded-lg gap-6 w-full h-fit px-8 gap-4 divide-y lg:divide-y-0 lg:divide-x divide-primary-100">
            <div class="flex flex-col w-full gap-6">
                <div class="space-y-2">
                    <h1 class="text-2xl">
                        {{ $t("testfleet.login.title_") }}
                    </h1>
                    <p class="text-black font-light">
                        {{ $t("testfleet.login.description") }}
                    </p>
                </div>
                <div class="space-y-6" v-if="!emailSent">
                    <form class="space-y-4" @submit="onSubmit" :validation-schema="schema">
                        <div class="flex flex-col gap-1.5">
                            <label for="email" class="font-semibold font-roboto text-md">
                                {{ $t("testfleet.booking.personalDetail.email") }}
                            </label>
                            <Field name="email" type="email" class="bg-primary bg-opacity-[4%] py-4 px-2"
                                data-cy='email' />
                            <ErrorMessage name="email" class="text-error-500" />
                        </div>
                        <div class="flex flex-col gap-5">
                            <Button @click="onSubmit" :loading="loading" data-cy="next-btn"
                                :label="$t('testfleet.login.form.submit')"
                                class="ps-4 pe-6 py-3 gap-2 text-nowrap text-sm flex items-center justify-center w-full" />
                        </div>

                    </form>
                </div>
                <div class="space-y-6" v-else>
                    <p class="font-roboto text-neutral-700"
                        v-html="$t('testfleet.login.form.success', { email: emailField.value.value as string || '' })">
                    </p>
                    <Button @click="reset"
                        class="ps-4 pe-6 py-3 gap-2 text-nowrap text-sm flex items-center justify-center w-full"
                        icon-left="ArrowLeft" :label="$t('testfleet.booking.form.button.back')" />
                </div>
            </div>
            <div class="flex flex-col gap-8 lg:px-8">
                <div class="flex flex-col gap-3" v-if="!AuthStore.isAuthenticated">
                    <h2 class="font-semibold text-2xl">
                        {{ $t("testfleet.login.overview.intro.title") }}
                    </h2>
                    <span v-html="$t('testfleet.login.overview.intro.description')"
                        class="font-light font-heading !leading-6"></span>
                </div>
                <div class="flex flex-col h-full justify-between gap-3" v-else>
                    <div class="flex flex-col gap-3 py-4 lg:py-0">

                        <h2 class="font-semibold text-2xl">
                            {{ $t("testfleet.login.secondary.title") }}
                        </h2>
                        <span v-html="$t('testfleet.login.secondary.description')"
                            class="font-light font-heading text-sm !leading-6"></span>
                    </div>
                    <Button @click="router.push('/book')"
                        variant="ghost"
                        class="ps-4 justify-self-end pe-6 py-3 gap-2 text-nowrap text-sm flex items-center justify-center w-full"
                        icon-right="ArrowRight" :label="$t('testfleet.booked.buttons.reserveBike')" />

                </div>

                <hr class="border-primary-100" v-if="!AuthStore.isAuthenticated" />
                <div class="py-2 flex flex-col gap-3" v-if="!AuthStore.isAuthenticated">

                    <h2 class="font-semibold text-lg">
                        {{ $t("testfleet.login.overview.leasing.title") }}
                    </h2>
                    <span v-html="$t('testfleet.login.overview.leasing.description')"
                        class="font-light font-heading !leading-6 [&>a]:text-primary [&>a]:underline"></span>

                </div>
            </div>

        </div>
    </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '../stores/Auth/AuthStore';
import Button from '@/components/Button/Button.vue'
import { Field, ErrorMessage, useForm, useField } from 'vee-validate';
//@ts-ignore
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue'
//@ts-ignore
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import * as yup from 'yup';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useTolgee } from '@tolgee/vue';
const AuthStore = useAuthStore();
const router = useRouter();
const tolgee = useTolgee();
const schema = yup.object({
    email: yup.string()
        .email(tolgee.value.t('testfleet.login.form.emailInvalid'))
        .required(tolgee.value.t('testfleet.login.form.emailRequired'))
});

const { handleSubmit, resetForm } = useForm({
    // vee-validate will be aware of computed schema changes
    validationSchema: schema,
    // turn this on so each step values won't get removed when you move back or to the next step
    keepValuesOnUnmount: true,
});
const loading = ref(false);
const emailField = useField('email');
const emailSent = ref(false);

const onSubmit = handleSubmit(async (values) => {
    loading.value = true;
    await AuthStore.authenticateWithEmail(values.email);
    // After submit show the next step
    emailSent.value = true;
    loading.value = false;
})

const reset = () => {
    resetForm();
    emailSent.value = false;
}
</script>
