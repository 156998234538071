export default {
  root: ({ props } : any) => ({
    class: [
      //Font
      "text-xs font-medium",
      //Alignments
      "inline-flex items-center justify-center",
      //Spacing
      "px-[0.5rem] py-1.5",
      //Shape
      {
        "rounded-md": !props.rounded,
        "rounded-full": props.rounded,
      },
      //Colors
      {
        "bg-highlight": props.severity === null || props.severity === "primary",
        "text-success-700 dark:text-success-300 bg-success-50 dark:bg-success-500/20":
          props.severity === "success",
        "text-surface-700 dark:text-surface-300 bg-surface-100 dark:bg-surface-500/20":
          props.severity === "secondary",
        "text-primary-700 dark:text-primary-300 bg-primary-100 dark:bg-primary-500/20":
          props.severity === "info",
        "text-orange-700 dark:text-orange-300 bg-orange-100 dark:bg-orange-500/20":
          props.severity === "warn",
        "text-error-700 dark:text-error-300 bg-error-100 dark:bg-error-500/20":
          props.severity === "danger",
        "text-surface-0 dark:text-surface-900 bg-surface-900 dark:bg-surface-0":
          props.severity === "contrast",
      },
    ],
  }),
  value: {
    class: "leading-normal",
  },
  icon: {
    class: "mr-1 text-sm",
  },
};
