<template>
  <form @submit="onSubmit">
    <div class="px-8" v-if="props.bike && !isFirstStep && !isConfirmationStep">
      <BikeChoiceCard class="w-full" :brand="props.bike.brand"
        :model="props.bike.model!" :image_url="props.bike.image_url!" @cardClicked="goToFirstStep" />
    </div>
    <slot />
    <div
      class="flex p-4 justify-center bg-white gap-2 w-full bottom-0 left-0 fixed z-[1] menu-shadow lg:p-0 lg:bg-transparent lg:static lg:bottom-auto lg:left-auto lg:pb-8">
      <slot name="actions" />

      <Button
        v-if="!isConfirmationStep"
        @click="goToPrev"
        variant="ghost"
        type="button"
        class="ps-4 pe-6 py-3 gap-2 text-sm flex items-center justify-center"
        :label="$t('testfleet.booking.form.button.back')"
        iconLeft="ArrowLeft"
        :disabled="isLoading && isLastStep"
        />

      <Button :submit="true" data-cy="next-btn"
        class="ps-4 pe-6 py-3 gap-2 text-nowrap text-sm flex items-center justify-center w-full text-white"
        :disabled="isDisabled"
        :loading="isLoading || loading"
        :label="$t(buttonKey)"
        iconRight="ArrowRight"
        @click="onSubmit"
        />

    </div>

  </form>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import { ref, computed, provide } from 'vue';
import Button from '@/components/Button/Button.vue';
import { useRouter } from 'vue-router';
import { Bike } from '@/services/bikes/types';
import BikeChoiceCard from '@/components/BikeChoiceCard/BikeChoiceCard.vue';
import { watch } from 'vue';
import { useAuthStore } from '@/stores/Auth/AuthStore';
const router = useRouter();

const props = defineProps({
  validationSchema: {
    type: Array,
    required: true,
  },
  bike: {
    type: Object as () => Bike,
    required: true,
  },
  submit: {
    type: Function,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

defineEmits(['submit']);
const currentStepIdx = ref(0);

// Injects the starting step, child <form-steps> will use this to generate their ids
const stepCounter = ref(0);
provide('STEP_COUNTER', stepCounter);

// Inject the live ref of the current index to child components
// will be used to toggle each form-step visibility
provide('CURRENT_STEP_INDEX', currentStepIdx);

const buttonKey = computed(() => {
     if (isLastStep.value) {
        return "testfleet.booking.form.button.submit";
      } else if (isConfirmationStep.value) {
        return "testfleet.booking.form.button.goToHome";
      } else if (isFirstStep.value) {
        return "testfleet.booking.form.button.pickBike";
      } else {
        return "testfleet.booking.form.button.next";
      }
});

// if this is the last step
const isLastStep = computed(() => {
  return currentStepIdx.value === stepCounter.value - 2; // -2 because the last step is confirmation step without form values
});

const isConfirmationStep = computed(() => {
  return currentStepIdx.value === stepCounter.value - 1;
});

const isFirstStep = computed(() => {
  return currentStepIdx.value === 0;
});

// extracts the indivdual step schema
const currentSchema = computed(() => {
  return props.validationSchema[currentStepIdx.value];
});

const isLoading = ref(false);

const { handleSubmit, validate, values } = useForm({
  // vee-validate will be aware of computed schema changes
  validationSchema: currentSchema,
  // turn this on so each step values won't get removed when you move back or to the next step
  keepValuesOnUnmount: true,
  validateOnMount: true,
});
const isValid = ref(true);

const isDisabled = computed(() => {
  // handle when errors is empty because
  return (isLastStep.value && isLoading.value) || !isValid.value;
});

// when current schema changes revalidate the form so the button will be disabled if they are not valid or filled
watch([currentSchema, values], async () => {
  const validForm = await validate({
    mode: 'validated-only'
  });
  isValid.value = validForm.valid;
});


// We are using the "submit" handler to progress to next steps
// and to submit the form if its the last step
const onSubmit = handleSubmit(async (values) => {
  if (isConfirmationStep.value) { router.replace('/my-bookings'); }

  // If the next step is the email confirmation step we need to send the verification code
  if (currentStepIdx.value === stepCounter.value - 4) {
    useAuthStore().requestVerificationCode(values.user);
  }

  if (!isLastStep.value) {
    window.scrollTo(0, 0);
    currentStepIdx.value++;
    return;
  }

  isLoading.value = true;
  // It is the last step so we submit the form
  const success = await props.submit(values);
  if (!success) {
    isLoading.value = false;
    return;
  }
  isLoading.value = false;
  window.scrollTo(0, 0);
  // Show the confirmation step
  currentStepIdx.value++;
});

const goToFirstStep = () => {
  currentStepIdx.value = 0;
};

function goToPrev() {
  if (currentStepIdx.value === 0) {
    // if we are at the first step, don't do anything
    // Go to / path
    router.push('/book');
  }
  currentStepIdx.value--;
}

</script>

<style scoped>
.menu-shadow {

  box-shadow: 0px 1px 16px rgba(22, 31, 74, 0.16);
  /* Box shadow */
}

/* Mediq Query no shadow on large screens */
@media (min-width: 1024px) {
  .menu-shadow {
    box-shadow: none;
  }
}
</style>
