<template>
    <Panel :header="props.header" toggleable class="mx-4">
        <slot name="content" />
        <template #togglericon="{ collapsed }">
            <ChevronDown :size="24" :class="!collapsed ? 'transform rotate-180' : ''" />
        </template>
    </Panel>
</template>

<script setup lang="ts">
import Panel from 'primevue/panel'
//@ts-ignore
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';

const props = defineProps({
    header: {
        type: String,
        required: true
    },
})
</script>
