<template>
    <div>
        <button
            class="flex items-center gap-2 text-sm font-medium px-4 py-2 border border-success text-success rounded-full enabled:hover:bg-success enabled:hover:text-white font-light w-full disabled:opacity-50"
            :class="{ 'bg-success text-white cursor-default': booking.admin_pickup_confirmed}"
            v-if="booking.status !== BookingStatus.Cancelled && !booking.admin_pickup_confirmed"
            @click="visible = true">
            <Check :size="20" />
            <!-- Conditional Button message -->
            <span v-if="booking.admin_pickup_confirmed">

                {{ $t('testfleet.hr.calendar.button.pickedup') }}
            </span>
            <span v-else>
                {{ $t('testfleet.hr.calendar.button.confirm.pickup') }}
            </span>
        </button>

        <Dialog v-model:visible="visible" modal :header="$t('testfleet.hr.calendar.pickup.helmet.title')"
            :draggable="false" :style="{ width: '28rem' }">
            <div class="flex flex-col gap-2">
                <span class="font-semibold text-md capitalize">
                    {{ booking.user?.name }} {{ booking.user?.last_name }}
                </span>

                <div class="inline-flex gap-2 items-center">
                    <span class="font-light text-black inline-flex items-center">
                        {{ formatDate(booking.start_date, $tolgee.getLanguage()!) }}
                    </span>
                    <ChevronRight :size="20" />
                    <span class="font-light text-black inline-flex items-center">
                        {{ formatDate(booking.end_date, $tolgee.getLanguage()!) }}
                    </span>
                </div>

            </div>
            <div class="flex justify-end gap-2 mt-5">
                <!-- No helmet provided -->
                <button
                    class="flex items-center justify-center gap-2 text-sm font-medium px-4 py-2 border border-success text-success rounded-full enabled:hover:bg-success enabled:hover:text-white font-light disabled:opacity-50"
                    @click="pickup(true)">
                    <Check :size="20" />
                    <span>{{ $t('testfleet.hr.calendar.button.yes') }}</span>
                </button>
                <!-- Helmet provided button -->
                <button
                    class="flex items-center justify-center gap-2 text-sm font-medium px-4 py-2 border border-error text-error rounded-full enabled:hover:bg-error enabled:hover:text-white font-light disabled:opacity-50"
                    @click="pickup(false)">
                    <Close :size="20" />
                    <span>{{ $t('testfleet.hr.calendar.button.no') }}</span>
                </button>
            </div>
        </Dialog>
    </div>

</template>

<script setup lang="ts">
import { Booking, BookingStatus } from '@/services/booking/types';
import { useDeploymentStore } from '@/stores/Deployment/DeploymentStore';
import { ref } from 'vue';
import Dialog from "primevue/dialog";
// @ts-ignore
import Check from "vue-material-design-icons/Check.vue";
// @ts-ignore
import ChevronRight from "vue-material-design-icons/ChevronRight.vue";
// @ts-ignore
import Close from "vue-material-design-icons/Close.vue";
import { formatDate } from '@/utils/date';

const visible = ref(false);
const deploymentStore = useDeploymentStore();

const { booking } = defineProps<{
    booking: Booking,
}>();

const pickup = async (picked_up_helmet: boolean) => {
    await deploymentStore.confirmHRPickup(booking.id, picked_up_helmet);
    visible.value = false;
}
</script>
