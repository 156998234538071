<template>
    <div v-if="AuthStore.isAuthenticated" class="flex flex-col justify-center items-center gap-16 max-w-[95%] w-full">
        <div class="flex flex-col rounded-lg gap-8 px-8 w-full">

            <div class="flex flex-col gap-2">
                <h1>
                    {{ $t("testfleet.overview.title") }}
                </h1>
                <p class="font-light text-sm leading-6">
                    {{ $t("testfleet.overview.description") }}
                </p>
            </div>

            <div class="flex flex-col gap-8 lg:w-full lg:grid lg:grid-cols-2">


                <BikeCardSkeleton v-for="i in 4" :key="i" v-if="loading" />


                <EmptyState class="h-full text-center col-span-3" v-else-if="!loading && bikes && bikes.length === 0"
                    :title="$t('testfleet.noBikes.title')" :description="$t('testfleet.noBikes.description')" />

                <BikeCard v-else v-for="bike in bikes" :bike="bike" />


            </div>
        </div>
        <LeasePriceDisclaimer v-if="shouldShowLeasePriceDisclaimer" />
    </div>
    <div class="flex flex-col justify-center items-center gap-16 max-w-[95%] w-full" v-else>
        <div class="flex flex-col gap-8 lg:w-full lg:grid lg:grid-cols-2">
            <BikeCardSkeleton v-if="!loading" v-for="i in 4" :key="i" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { onBeforeMount } from 'vue';
import BikeCard from '@/components/BikeCard/BikeCard.vue'
import BikeCardSkeleton from '@/components/BikeCard/BikeCardSkeleton.vue'
import EmptyState from '@/components/EmptyState/EmptyState.vue'
import LeasePriceDisclaimer from '@/components/LeasePriceDisclaimer/LeasePriceDisclaimer.vue'
import { useBikeStore } from '@/stores/Bike/BikeStore';
import { useAuthStore } from '@/stores/Auth/AuthStore';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { computed } from 'vue';
import { useTolgee } from '@tolgee/vue';
import { getCurrentLocaleValue } from '@/utils/i18n';

const loading = ref(true)
const bikeStore = useBikeStore();
const AuthStore = useAuthStore();

onBeforeMount(async () => {
    if (!AuthStore.isAuthenticated) {
        await AuthStore.authenticate()
    }
    await bikeStore.fetchDeploymentBikes()
    loading.value = false
})

const locale = useTolgee().value.getLanguage();
const { bikes } = storeToRefs(bikeStore)

const shouldShowLeasePriceDisclaimer = computed(() => {
    // Check if one of the bikes have a lease price
    return bikes.value?.some(bike => {
        let lp = getCurrentLocaleValue(bike.lease_price, locale);
        return lp !== null && lp !== undefined && lp !== '';
    });
})
</script>
