import { defineStore } from "pinia";
import { LocalStorageService } from "../../services/localstorage";
import { AuthService } from "../../services/auth";
import { jwtDecode } from "jwt-decode";
import { AuthType } from "@/router/AuthGuard";
import { useDeploymentStore } from "../Deployment/DeploymentStore";
import { useUserStore } from "../User/UserStore";
import {
  RequestVerificationDto,
  VerifyRequestDto,
} from "@/services/auth/types";

export const useAuthStore = defineStore("AuthStore", {
  state: () => ({
    isAuthInitialized: false,
    isAuthenticated: false,
  }),
  getters: {},
  actions: {
    async authenticate() {
      // First check if there is an otp code in the URL
      await this.authenticateWithOtp();
      if (this.isAuthenticated) {
        this.isAuthInitialized = true;
        return;
      }


      // next we check if there is a code in the URL and overwrite the existing code if one is found
      const url = new URL(document.location.href);
      const urlCode = url.searchParams.get("code");
      // If the user has a code in the URL and no auth type, set the auth type to 'deployment_code'
      if (urlCode) {
        LocalStorageService.setDeploymentCode(urlCode);
        this.handleNewCode(urlCode);
      }

      // next check if the user has a token in local storage
      await this.handleJWTToken();
      if (this.isAuthenticated) {
        this.isAuthInitialized = true;
        return;
      }

      // finally check if the user has a code in local storage
      await this.handleCode();
      this.isAuthInitialized = true;
      return;
    },

    async authenticateWithOtp() {
      const url = new URL(document.location.href);
      const otpCode = url.searchParams.get("otp");
      if (!otpCode) return;

      try {
        const data = await AuthService.authenticateWithOtp(otpCode);
        // We wan't to keep the old session, in case the new session is invalid
        const oldToken = LocalStorageService.getJwtToken();
        if (data) {
          LocalStorageService.setJwtToken(data);
          await this.handleJWTToken();
        }

        if (!this.isAuthenticated && oldToken) {
          LocalStorageService.setJwtToken(oldToken);
          await this.handleJWTToken();
        }
      } catch { }
    },

    async authenticateWithVerificationCode(verificationDto: VerifyRequestDto) {
      const data = await AuthService.authenticateWithVerificationCode(
        verificationDto
      );
      if (data) {
        LocalStorageService.setJwtToken(data);
        await this.handleJWTToken();
      }
    },

    async requestVerificationCode(verificationDto: RequestVerificationDto) {
      if (LocalStorageService.getJwtToken()) return;
      verificationDto.deployment_code =
        LocalStorageService.getDeploymentCode() || undefined;
      const data = await AuthService.requestVerificationCode(verificationDto);
      return data;
    },

    async authenticateWithEmail(email: string) {
      const response = AuthService.authenticateWithEmail(email);
      return response;
    },

    async authenticateAdmin(email: string) {
      const response = AuthService.authenticateAdmin(email);
      return response;
    },

    async logout() {
      LocalStorageService.clearJwtToken();
      useUserStore().clearUser();
      await this.handleCode();
    },

    async handleCode() {
      const storageCode = LocalStorageService.getDeploymentCode();
      if (storageCode) {
        LocalStorageService.setAuthType(AuthType.DEPLOYMENT_CODE);
        await useDeploymentStore().getByCode(storageCode);
        if (useDeploymentStore().getDeployment) {
          this.isAuthenticated = true;
        } else {
          LocalStorageService.clearDeploymentCode();
          LocalStorageService.clearAuthType();
        }
        return;
      }
    },

    async handleJWTToken() {
      const jwtToken = LocalStorageService.getJwtToken();
      if (jwtToken) {
        //check if token is still valid based on expiry date
        try {
          const decodedToken: { exp: number; deployments: number[] } =
            jwtDecode(jwtToken.access_token);
          const expiryDate = new Date(decodedToken.exp * 1000);
          const currentDate = new Date();
          if (expiryDate < currentDate) {
            this.logout();
            return;
          }

          LocalStorageService.setAuthType(AuthType.JWT_TOKEN);
          await useUserStore().fetchUser();
          if (useUserStore().getUser) {
            this.isAuthenticated = true;
            const deploymentId = useDeploymentStore().getDeployment?.id;
            if (
              deploymentId &&
              decodedToken.deployments.includes(deploymentId)
            ) {
              if (
                window.location.pathname === "/login"
              ) {
                window.location.href = "/hr";
              }
            }
          } else {
            this.logout();
            return;
          }
        } catch {
          this.logout();
          return;
        }
      }
    },


    async handleNewCode(code : string) {
      // Get user deployments
      // only attach if there is a user (jwt token)
      const jwtToken = LocalStorageService.getJwtToken();
      if (jwtToken) {
        useDeploymentStore().attachUserToDeployment(code);
      }
    }
  },
});
