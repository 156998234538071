<template>
    <div class="flex flex-col gap-2 py-4">
        <div class="inline-flex items-center gap-2">
            <span class="border rounded-full w-10 h-10 flex items-center justify-center font-medium uppercase"
                :class="{
                    'bg-secondary-100 text-secondary-600 border-secondary-200': props.variant === 'secondary',
                    'bg-tertiary-50 text-tertiary-400 border-tertiary-100': props.variant === 'tertiary'

                }">
                {{ 
                
                remark.author.name[0] + remark.author.last_name[0]
                }}
            </span>
            <span class="font-medium capitalize">
                {{ remark.author.name }} {{ remark.author.last_name }}
            </span>
        </div>
        <p class="font-light break-words">
            {{ remark.remark }}
        </p>

        <!-- Images -->
        <div class="flex flex-row gap-2">
            <Image v-for="image in remark.images" :src="image.image_url" :alt="`Image ${image.image_url}`" preview width="100" height="100" />
        </div>

        <span class="text-neutral-400 font-light">
            {{ 
                moment(remark.created_at).locale($tolgee.getLanguage() as string).format('DD/MM/YY - HH:mm')
            }}
        </span>
    </div>
</template>

<script setup lang="ts">
import { Remark } from '@/services/remark/types';
import Image from 'primevue/image'
import moment from 'moment'
const props = defineProps<
    {
        remark: Remark;
        variant: 'secondary' | 'tertiary';
    }>();


</script>
