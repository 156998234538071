import axios from 'axios'
import { LocalStorageService } from "../services/localstorage"
import { useAuthStore } from '../stores/Auth/AuthStore';

// Create an Axios instance
const AxiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT
});

// Add a request interceptor
AxiosClient.interceptors.request.use((config) => {
  // Get the JWT token from the auth store
  const token = LocalStorageService.getJwtToken()?.access_token;     // replace with the method to get the token from your auth store

  // If the token exists, add it to the Authorization header
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
AxiosClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  // logout the user if the token is invalid and the url is not /auth/*
  if (error.response.status === 401 && !error.config.url?.includes('/auth/')) {
    useAuthStore().logout();
  }
  return Promise.reject(error);
});

export default AxiosClient;