import {
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

import { useAuthStore } from "@/stores/Auth/AuthStore";
import { useDeploymentStore } from "@/stores/Deployment/DeploymentStore";
import { LocalStorageService } from "@/services/localstorage";


export enum AuthType {
  PUBLIC = "public",
  DEPLOYMENT_CODE = "deployment_code",
  JWT_TOKEN = "jwt_token",
  DEPLOYMENT_ADMIN = "deployment_admin",
}

export const AuthGuard = async (
  to: RouteLocationNormalized,
  // @ts-ignore
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const routeAuthType = to.meta.authType as AuthType;
  const authenticated = useAuthStore().isAuthenticated;


  if (routeAuthType === AuthType.DEPLOYMENT_ADMIN) {
    // Check if the user is an admin of the deployment
    const deployment = useDeploymentStore().getDeployment;
    const jwtPayload = LocalStorageService.getJWTPayload();

    if (!deployment || !jwtPayload) {
      return next({path: "/login", query: to.query});
    }

    if (jwtPayload.deployments.includes(deployment.id)) {
      return next();
    }
    // Id the user is not an admin of the deployment, redirect the home page
    return next({path: "/"});
  }


  if(routeAuthType === AuthType.PUBLIC || authenticated){
     return next();
  }


  return next({path: "/login", query: to.query});
};
