<template>
    <!-- Header -->
    <div class="flex flex-col gap-2 px-8">
        <h1>
            <slot name="title" />
        </h1>
        <p class="font-light text-lg">
            <slot name="description" />
        </p>
    </div>
    <slot name="form" />

</template>