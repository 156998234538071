export const getCurrentLocaleValue = (obj: { [key: string]: string } | null | undefined, locale: string) => {

  if (!obj) return '';

  if (locale = 'nl-BE')
    locale = 'nl';

  if (obj[locale]) {
    return obj[locale];
  }
  // Return the first value in the object
  return Object.values(obj)[0];
}