export const formatDate = (date: Date, locale: string, type : 'long' | 'short' | 'digit' | 'weekday' = 'short',) => {

  if (type === 'long') return new Date(date).toLocaleDateString(locale, {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
  })


  if (type === 'digit') return new Date(date).toLocaleDateString(locale, {
    year: 'numeric', month: 'numeric', day: 'numeric'
  })

  if (type === 'weekday') return new Date(date).toLocaleDateString(locale, {
    year: 'numeric', month: 'numeric', day: 'numeric', weekday: "long"
  })

  return new Date(date).toLocaleDateString(locale, {
    weekday: 'short', year: 'numeric', month: 'long', day: '2-digit'
  })
}