<template>
    <div class="bg-neutral-50 px-8 py-6 text-neutral-500 font-roboto w-full text-center">
        <span v-html="$t('testfleet.lease_price_disclaimer')" class="[&>a]:text-neutral-500 [&>a]:underline">

        </span>
    </div>
</template>

<script lang="ts" setup>

</script>
