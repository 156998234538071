<template>
    <form-step>
        <form-layout class="col-span-2 h-full">
            <template v-slot:title>
                {{ $t("testfleet.booking.confirmation.title") }}
            </template>

            <template v-slot:description>
                {{ $t("testfleet.booking.confirmation.description") }}
            </template>
            <template v-slot:form>
                
                <div class="space-y-8 pb-16 lg:pb-0 px-8" v-if="bookingStore.booking" data-cy="booking-confirmed">
                    <div v-html="$t('testfleet.booking.confirmation.extendedDescription', { bike: bikeStore.currentBike?.brand + ' ' + bikeStore.currentBike?.model})"
                        class="space-y-2 font-roboto"></div>
                    <div class="space-y-3">
                        <h3>
                            {{ $t('testfleet.booking.reserve.reservationDetail.title') }}
                        </h3>
                        <!-- Details -->
                        <ReservationDetail :booking="bookingStore.booking" :location="bikeStore.currentBike?.location" />
                    </div>
                </div>
            </template>
        </form-layout>
    </form-step>
</template>

<script setup lang="ts">
  import FormStep from '@/components/Form/FormStep.vue'
  import FormLayout from '@/components/Form/FormLayout.vue'
  import ReservationDetail from '@/components/Booking/ReservationDetail/ReservationDetail.vue'
  import { useBookingStore } from '@/stores/Booking/BookingStore';
  import { useBikeStore } from '@/stores/Bike/BikeStore';

  const bookingStore = useBookingStore();
  const bikeStore = useBikeStore();
</script>
