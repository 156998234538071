import AxiosClient from "../api";
import { User } from "./types";

const fetchUser = async () => {
  try {
    const user = await AxiosClient.get("/auth/me");
    return user.data as User;
  } catch (error) {
    return null;
  }
};

export const UserService = {
  fetchUser,
};
