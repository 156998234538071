<template>
  <div v-if="!loading" class="flex justify-center flex-1">
    <div class="flex flex-col rounded-lg gap-4 pb-16 lg:pb-0 ">
      <form-wizard :loading="checkingVerificationCode" :validation-schema="validationSchema" :submit="onSubmit"
        :bike="currentBike" v-if="currentBike" class="gap-4 rounded-lg lg:rounded-s-none flex-1 flex flex-col">
        <!-- Overview Step -->
        <OverviewStep :bike="currentBike" />
        <!-- Pickup Moment Step -->
        <PickupStep :bike="currentBike" />
        <!-- Personal Details Step -->
        <PersonalStep :bikeType="currentBike.propulsion" :user="user.getUser.value!" />
        <!-- Requirements step -->
        <RequirementsStep :bikeType="currentBike.propulsion"></RequirementsStep>
        <!-- Email Verification Step Only show if the user is not logged in -->
        <EmailVerificationStep v-if="!initiallyLoggedIn" @checking-verification-code="checkVerificationCode">
        </EmailVerificationStep>
        <!-- Confirmation Step -->
        <ConfirmationStep />
      </form-wizard>
    </div>
    <Toast />
  </div>

  <OverviewStepSkeleton v-else />
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useBikeStore } from '../stores/Bike/BikeStore';
import { useRoute } from 'vue-router';
import OverviewStep from '@/components/Booking/OverviewStep/OverviewStep.vue';
import OverviewStepSkeleton from '@/components/Booking/OverviewStep/OverviewStepSkeleton.vue';
import PickupStep from '@/components/Booking/PickupStep/PickupStep.vue';
import PersonalStep from '@/components/Booking/PersonalStep/PersonalStep.vue';
import ConfirmationStep from '@/components/Booking/ConfirmationStep/ConfirmationStep.vue';
import FormWizard from '@/components/Form/FormWizard.vue';
import { useToast } from 'primevue/usetoast';
import moment from 'moment';
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { useBookingStore } from '../stores/Booking/BookingStore';
import { CreateBookingDto } from '../services/booking/types';
import { useTolgee } from '@tolgee/vue';
//@ts-ignore
import Loader from '@/components/Loader/Loader.vue'
import RequirementsStep from '@/components/Booking/RequirementsStep/RequirementsStep.vue';
import { useUserStore } from '@/stores/User/UserStore';
import { useForm } from 'vee-validate';
import EmailVerificationStep from '@/components/Booking/EmailVerificationStep/EmailVerificationStep.vue';
import { computed } from 'vue';
import { isAxiosError } from 'axios';

const route = useRoute();
const bikeStore = useBikeStore();
const bookingStore = useBookingStore();
const userStore = useUserStore();
const toast = useToast();
const bikeId = Number(route.params.id);
const user = storeToRefs(userStore);
const form = useForm();

const initiallyLoggedIn = user.user.value !== null;

let loading = ref(true);

const checkingVerificationCode = ref(false);

const tolgee = useTolgee();

onBeforeMount(async () => {
  // Get the current bike
  await bikeStore.fetchBike(bikeId).then(() => {
    loading.value = false;
  });
});

const { currentBike } = storeToRefs(useBikeStore());


if (user.user.value) {
  const currentUser = user.getUser;
  form.setValues({
    user: {
      first_name: currentUser.value?.name,
      last_name: currentUser.value?.last_name,
      email: currentUser.value?.email,
    },
    email_verified: true,
  })
}

const validationSchema = computed(() => {
  if (form.values.email_verified) {
    return [
      // Overview step no validation
      yup.object({
      }),
      // Pickup Moment step validate date
      yup.object({
        slot: yup.object({
          pickup: yup.object({
            date: yup.string().required(),
          }),
          dropoff: yup.object({
            date: yup.string().required(),
          }),
        })
      }),
      yup.object({
        user: yup.object({
          first_name: yup.string().required(tolgee.value.t('testfleet.booking.personalDetail.errors.required')),
          last_name: yup.string().required(tolgee.value.t('testfleet.booking.personalDetail.errors.required')),
          email: yup.string()
            .email(tolgee.value.t('testfleet.booking.personalDetail.errors.email'))
            .required(tolgee.value.t('testfleet.booking.personalDetail.errors.required')),
          // Is optional but if bike is speed pedelec, it should be filled
          birth_date: yup.date()
            .typeError(tolgee.value.t('testfleet.booking.personalDetail.errors.birth_date.invalid'))
            // @ts-ignore
            .when('$other', (other: any, schema: any) => {
              return currentBike?.value?.propulsion === 'speed_pedelec' ? schema.required(tolgee.value.t('testfleet.booking.personalDetail.errors.required')) : schema;
            })
            // @ts-ignore
            .transform((value: any, rawValue) => { let correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate(); return correctDate })
            .min(moment(new Date(1924, 1, 1)).toDate(), tolgee.value.t('testfleet.booking.personalDetail.errors.birth_date.max_100'))
            .max(new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDate()), tolgee.value.t('testfleet.booking.personalDetail.errors.birth_date.min_16'))

        }),
      }),
      yup.object({
        requirements: yup.object({
          lock: yup.bool().oneOf([true]).default(false),
          terms: yup.bool().oneOf([true]).default(false),
          privacy: yup.bool().oneOf([true]).default(false),
          // Speedpedelec
          helmet: yup.bool().oneOf([true]).default(currentBike?.value?.propulsion != 'speed_pedelec'),
          gdpr: yup.bool().oneOf([true]).default(currentBike?.value?.propulsion != 'speed_pedelec'),
          license: yup.bool().oneOf([true]).default(currentBike?.value?.propulsion != 'speed_pedelec'),
        }),

      }),
      yup.object({
      }),
    ]

  } else {
    return [
      // Overview step no validation
      yup.object({
      }),
      // Pickup Moment step validate date
      yup.object({
        slot: yup.object({
          pickup: yup.object({
            date: yup.string().required(),
          }),
          dropoff: yup.object({
            date: yup.string().required(),
          }),
        })
      }),
      yup.object({
        user: yup.object({
          first_name: yup.string().required(tolgee.value.t('testfleet.booking.personalDetail.errors.required')),
          last_name: yup.string().required(tolgee.value.t('testfleet.booking.personalDetail.errors.required')),
          email: yup.string()
            .email(tolgee.value.t('testfleet.booking.personalDetail.errors.email'))
            .required(tolgee.value.t('testfleet.booking.personalDetail.errors.required')),
          // Is optional but if bike is speed pedelec, it should be filled
          birth_date: yup.date()
            .typeError(tolgee.value.t('testfleet.booking.personalDetail.errors.birth_date.invalid'))
            // @ts-ignore
            .when('$other', (other: any, schema: any) => {
              return currentBike?.value?.propulsion === 'speed_pedelec' ? schema.required(tolgee.value.t('testfleet.booking.personalDetail.errors.required')) : schema;
            })
            // @ts-ignore
            .transform((value: any, rawValue) => { let correctDate = moment(rawValue, ['yyyy-mm-dd']).toDate(); return correctDate })
            .min(moment(new Date(1924, 1, 1)).toDate(), tolgee.value.t('testfleet.booking.personalDetail.errors.birth_date.max_100'))
            .max(new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDate()), tolgee.value.t('testfleet.booking.personalDetail.errors.birth_date.min_16'))

        }),
      }),
      yup.object({
        requirements: yup.object({
          lock: yup.bool().oneOf([true]).default(false),
          terms: yup.bool().oneOf([true]).default(false),
          privacy: yup.bool().oneOf([true]).default(false),
          // Speedpedelec
          helmet: yup.bool().oneOf([true]).default(currentBike?.value?.propulsion != 'speed_pedelec'),
          gdpr: yup.bool().oneOf([true]).default(currentBike?.value?.propulsion != 'speed_pedelec'),
          license: yup.bool().oneOf([true]).default(currentBike?.value?.propulsion != 'speed_pedelec'),
        }),

      }),
      yup.object({
        email_verified: yup.bool().oneOf([true]).default(false),
      }),
      yup.object({
      }),
    ]
  }
})

const onSubmit = async (formData: CreateBookingDto) => {
  try {
    await bookingStore.bookBike(formData);
    return true;
  } catch (error) {
    if (isAxiosError(error) && error.response && error.response.data.errors) {
      switch (error.response.data.errors) {
        case 'The user already has a booking for this slot.':
          toast.add({ severity: 'error', summary: tolgee.value.t('testfleet.booking.form.errors.bookingFailed.title'), detail: tolgee.value.t('testfleet.booking.form.errors.bookingFailed.alreadyBooked') });
          break;
        default:
          toast.add({ severity: 'error', summary: tolgee.value.t('testfleet.booking.form.errors.bookingFailed.title'), detail: tolgee.value.t('testfleet.booking.form.errors.bookingFailed.description') });
      }
    } else {
      toast.add({ severity: 'error', summary: tolgee.value.t('testfleet.booking.form.errors.bookingFailed.title'), detail: tolgee.value.t('testfleet.booking.form.errors.bookingFailed.description') });
    }
  }
  return false;
};

const checkVerificationCode = (checking: boolean) => {
  checkingVerificationCode.value = checking;
};

</script>
