import { defineStore } from "pinia";
import { Bike } from "../../services/bikes/types";
import { BikeService } from "../../services/bikes";

interface BikeState {
  bikes: Bike[];
  currentBike?: Bike | null;
}

export const useBikeStore = defineStore("BikeStore", {
  // State
  state: (): BikeState => ({
    bikes: [],
    currentBike: undefined,
  }),
  // Getters
  getters: {
    getBikes(state) {
      return state.bikes;
    },
    getBike: (state) => (id: number) => {
      return state.bikes.find((bike) => bike.id === id);
    },
    getCurrentBike(state) {
      return state.currentBike;
    },
  },
  // Actions
  actions: {
    async fetchDeploymentBikes(force = false) {
      if (!force && this.bikes && this.bikes.length > 0) return;
      const response = await BikeService.fetchDeploymentBikes();
      this.bikes = response?.data;
    },

    setCurrentBike(bike: Bike) {
      this.currentBike = bike;
    },
    async fetchBike(id: number) {
      try {
        let bike = null;

        bike = await BikeService.fetchDeploymentBike(id);
        if (!bike) return null;
        this.setCurrentBike(bike);
        // return the bike
        return bike;
      } catch (error) {
        return null;
      }
    },
  },
});
