import AxiosClient from "../api";
import { Bike } from "./types";
import { LocalStorageService } from "../localstorage"
import { useDeploymentStore } from "@/stores/Deployment/DeploymentStore";

const fetchDeploymentBikes = async () => {
  const DeploymentStore = useDeploymentStore();
  const deploymentCode = DeploymentStore.getDeployment?.login_code;
  if (!deploymentCode) {
    return;
  }
  try {
    return await AxiosClient.get('/deployments/code/' + deploymentCode + '/bicycles');
  } catch (error) {
    // Invalid deployment code -> remove it from localstorage
    LocalStorageService.clearLocalStorage();
    // Redirect to the login page
    window.location.href = '/';
  }
}


const fetchBike = async (deployment_id: number, id: number): Promise<Bike> => {
  const data = (await AxiosClient.get('/deployments/' + deployment_id + '/bicycles/' + id)).data;
  data.bicycle.availabilities = data.availability;
  data.bicycle.location = data.deployment.location;
  return data.bicycle;
}

const fetchDeploymentBike = async (id: number): Promise<Bike | undefined> => {
  const deploymentStore = useDeploymentStore();
  const deploymentCode = deploymentStore.getDeployment ? deploymentStore.getDeployment.login_code : undefined;
  if (!deploymentCode) {
    return;
  }
  const data = (await AxiosClient.get('/deployments/code/' + deploymentCode + '/bicycles/' + id)).data;
  data.availability.days = Object.keys(data.availability.days).map(key => data.availability.days[key]);
  data.bicycle.availabilities = data.availability;
  data.bicycle.lease_price = data.lease_price
  data.bicycle.location = data.deployment.location;
  data.bicycle.inactive = data.inactive;
  return data.bicycle;
}


export const BikeService = {
  fetchDeploymentBikes,
  fetchBike,
  fetchDeploymentBike
}
