<template>
    <form-step>
        <form-layout class="col-span-2 h-full">
            <template v-slot:title>
                {{ $t("testfleet.booking.emailVerification.title") }}
            </template>

            <template v-slot:description>
                {{ $t("testfleet.booking.emailVerification.description") }}
            </template>
            <template v-slot:form>
                <div class="space-y-8 pb-8 lg:pb-0 px-8">
                    <span class="font-roboto font-regular text-neutral-700"
                        v-html="$t('testfleet.booking.emailVerification.text', { email: user.email })"></span>
                </div>
                <div class="card flex justify-center flex-col items-center gap-4">
                    <div class="flex justify-center flex-col items-center gap-2">
                        <InputOtp v-model="verificationValue" data-cy="verification-input"></InputOtp>
                        <span class="text-error-500 text-sm" data-cy="verification-error">
                            {{ verificationError }}
                        </span>
                    </div>
                    <Button
                      variant="text"
                      type="button"
                      class="pb-12"
                      @click="sendVerification"
                      :loading="loading"
                      :label="$t('testfleet.booking.emailVerification.resend')" />
                </div>
            </template>
        </form-layout>
    </form-step>
</template>

<script setup lang="ts">
import FormStep from '@/components/Form/FormStep.vue'
import FormLayout from '@/components/Form/FormLayout.vue'
import InputOtp from 'primevue/inputotp';
import { useField } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import { useAuthStore } from '@/stores/Auth/AuthStore';
import { useTolgee } from '@tolgee/vue';
import Button from '@/components/Button/Button.vue';

const emit = defineEmits(['checking-verification-code']);

const loading = ref(false);

const authStore = useAuthStore();
const tolgee = useTolgee();
const verificationValue = ref('');
const verificationError = ref<string | null>(null);


const emailField = useField('user.email');
const firstNameField = useField('user.first_name');
const lastNameField = useField('user.last_name');
const emailVerifiedField = useField('email_verified');


const user = computed(() => {
    return {
        email: emailField.value.value as string,
        first_name: firstNameField.value.value as string,
        last_name: lastNameField.value.value as string,
    }
});

// On mount we send the verification code if the user is not verified
// If 4 digits are entered, submit the form
watch(verificationValue, async (value) => {
    if (value.length != 4) {
        return
    }
    emit('checking-verification-code', true);
    // We need to submit the form here
    try {
        // set loading state of submit button in document to true
        document.querySelector('button[type="submit"]')?.classList.add('loading');
        await authStore.authenticateWithVerificationCode({
            email: emailField.value.value as string,
            verification_code: verificationValue.value
        })
        // Reset Error
        if (verificationError.value) verificationError.value = null;
        // Set email verified to true
        emailVerifiedField.setValue(true);
        // Submit the form
        document.querySelector('form')?.dispatchEvent(new Event('submit'));
    } catch (error) {
        verificationError.value = tolgee.value.t('testfleet.booking.emailVerification.invalidCode');
    }
    emit('checking-verification-code', false);
})



const sendVerification = async () => {
    loading.value = true;
    await authStore.requestVerificationCode(user.value);
    loading.value = false;
}

</script>
