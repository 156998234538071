<template>
    <div v-if="!loading" class="flex flex-col justify-center pt-4 flex-1 max-w-[95%] w-full">
        <div class="flex flex-col rounded-lg gap-8">
            <form-layout class="col-span-2 h-full">
                <template v-slot:title>
                    {{ $t("testfleet.booked.title") }}
                </template>

                <template v-slot:description>
                    <span data-cy="has-bookings-description" v-if="hasBookings">
                      {{ $t("testfleet.booked.description") }}
                    </span>
                    <span data-cy="has-no-bookings-description" v-else>
                      {{ $t("testfleet.booked.no-booking-description") }}
                    </span>
                </template>
                <template v-slot:form>
                    <div class="space-y-8 px-8">
                        <BookedBikeCard v-for="(booking) in bookings" :booking="booking"
                            :key="`booking-${booking.id}`"/>
                        <div class="w-full flex flex-col justify-center space-y-8">
                            <button data-cy="book-bike-button" v-if="!hasBookings" @click="() => router.push({ path: '/book' })"
                                class="flex items-center justify-between gap-2.5 p-5 bg-white text-[22px] rounded-2xl font-semibold w-full border-2 border-primary text-start">
                                {{ $t("testfleet.booked.buttons.reserveBike") }}
                                <span class="bg-primary
                                text-white flex justify-center items-center rounded-full min-w-[44px] min-h-[44px]">
                                    <arrow-right :size="24" />
                                </span>
                            </button>
                        </div>
                    </div>
                    <LeasePriceDisclaimer data-cy="lease-disclaimer" v-if="hasBookings && shouldShowLeasePriceDisclaimer" />
                </template>
            </form-layout>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import BookedBikeCard from '@/components/BookedBikeCard/BookedBikeCard.vue'
//@ts-ignore
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import FormLayout from '@/components/Form/FormLayout.vue'
import { useUserStore } from '@/stores/User/UserStore';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { Booking, BookingStatus } from '@/services/booking/types';
import LeasePriceDisclaimer from '@/components/LeasePriceDisclaimer/LeasePriceDisclaimer.vue';
import { getCurrentLocaleValue } from '@/utils/i18n';
import { useTolgee } from '@tolgee/vue';


const router = useRouter();
let loading = ref(true);

onBeforeMount(async () => {
    loading.value = false;
})

const { user } = storeToRefs(useUserStore());
const locale = useTolgee().value.getLanguage();
const bookings = computed(() => {
    // Only show bookings that are cancelled, completed, or picked up
    const noShowStatuses = [BookingStatus.Cancelled, BookingStatus.DroppedOff];
    return user?.value?.bookings.filter((booking: Booking) => booking.status && !noShowStatuses.includes(booking.status));
});

const hasBookings = computed(() => bookings.value?.length !== 0);

const shouldShowLeasePriceDisclaimer = computed(() => {
    // Check if one of the bikes have a lease price
    if (!hasBookings.value) return false;
    return bookings.value?.some((booking: Booking) => {
        let lp = getCurrentLocaleValue(booking.bicycle?.lease_price, locale);
        return lp !== null && lp !== undefined && lp !== '';
    });
});
</script>
