<template>
    <Button class="py-4 rounded-full inline-flex gap-2 disabled:opacity-30"
        :class="{ 'bg-primary text-white': props.variant === 'default',
        'text-primary bg-white border border-primary disabled:border-gray-600 disabled:text-gray-600 disabled:hover:bg-white': props.variant === 'outline',
        'bg-primary-50 text-primary': props.variant === 'ghost',
        'text-primary hover:underlin': props.variant === 'text'
        }"
        :disabled="props.disabled"
        :loading="props.loading"
        @click="handleClick"
        >
        <loading v-if="props.loading" class="animate-spin" />
        <template v-else>
          <IconLeftComponent v-if="props.iconLeft" />
          <span>{{ props.label }} </span>
          <IconRightComponent v-if="props.iconRight" />
        </template>
    </Button>
</template>

<script setup lang="ts">
  import { PropType, defineProps } from 'vue';
  import { ButtonVariant } from './ButtonType';
  import Button from 'primevue/button';
  //@ts-ignore
  import Loading from 'vue-material-design-icons/Loading.vue';
  //@ts-ignore
  import ArrowRight from 'vue-material-design-icons/ArrowRight.vue';
  //@ts-ignore
  import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue'
  import { computed } from 'vue';

  const props = defineProps({
    variant: {
      type: String as PropType<ButtonVariant>,
      default: 'default',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    iconLeft: {
      type: String,
      default: '',
      required: false,
    },
    iconRight: {
      type: String,
      default: '',
      required: false,
    },
  })

  const emit = defineEmits(['click']);

  const handleClick = () => {
    if (props.disabled || props.loading) {
      return;
    }
    emit('click');
  }

  const icons: { [key: string]: any } = {
    Loading,
    ArrowRight,
    ArrowLeft
  };

  const IconLeftComponent = computed(() => {
    if (props.iconLeft && icons[props.iconLeft]) {
      return icons[props.iconLeft];
    }
    return null;
  });

  const IconRightComponent = computed(() => {
    if (props.iconRight && icons[props.iconRight]) {
      return icons[props.iconRight];
    }
    return null;
  });

</script>
