export default {
  input: {
    class: "hidden",
  },
  content: {
    class: [
      
    ],
  },
  empty: {
    class: [
    ]
  },
  file: {
    class: [
      "flex",
      "items-center",
      "flex-wrap",
      "p-4",
      "mb-2",
      "last:mb-0",
      "border",
      "border-surface-200",
      "dark:border-surface-700",
      "gap-2",
      "rounded",
    ],
  },
  thumbnail: {
    class: "shrink-0",
  },
  fileName: {
    class: "mb-2 break-all",
  },
  fileSize: {
    class: "mr-2",
  },
  uploadicon: {
    class: "mr-2",
  },
};
