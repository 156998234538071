<template>
    <div class="flex justify-center">
        <button @click="visible = true" v-if="!header || (header && remarks.length > 0)"
            v-tooltip.top="$t('testfleet.hr.calendar.button.remark')"
            class="flex items-center gap-2 text-sm font-medium px-2 py-2 border border-primary-300 text-primary rounded-full hover:bg-primary hover:text-white font-light w-full"
            :class="{ 'bg-primary-50 text-primary border-0 px-4 py-2' : header }">
            <MessageTextOutline :size="20" />
            <span v-if="header">

                {{ $t('testfleet.hr.calendar.button.remark') }}
            </span>
            <span v-if="header"
                class="text-xs font- bg-white text-primary px-2 py-2 rounded-full w-6 h-6 flex items-center justify-center">
                {{ remarks.length }}
            </span>
        </button>
        <Dialog v-model:visible="visible" modal :header="$t('testfleet.hr.remark.title')" :draggable="false"
            :dismissableMask="true">

            <template #header>
                <div class="flex flex-col justify-center gap-2">
                    <h2 class="p-0 text-xl font-medium">{{ $t('testfleet.hr.remark.title') }}</h2>
                    <div class="inline-flex gap-2">
                        <span class="text-tertiary font-light">
                            {{ props.bike.bicycle_code }}
                        </span>
                        <span class="font-light">
                            {{ props.bike.brand }} {{ props.bike.model }}
                        </span>
                    </div>
                </div>
            </template>
            <div class="relative">
                <textarea class="w-full h-32 p-4 bg-primary-50 rounded-md text-sm font-light focus-visible:outline-none"
                    v-model="remark" :placeholder="$t('testfleet.hr.remark.placeholder')"></textarea>

                <button @click="postRemark" :disabled="loading"
                    class="absolute bottom-4 right-4 z-100 w-fit text-sm bg-primary text-white font-medium px-4 py-2 border border-primary text-primary rounded-full font-light inline-flex gap-2 disabled:opacity-50">
                    <span class="animate-spin" v-if="loading && header">
                        <loading class="animate-spin" :size="20" />
                    </span>

                    {{ $t('testfleet.hr.remark.submit') }}
                </button>
            </div>

            <!-- Remark list -->
            <div class="flex flex-col gap-2">
                <h3 class="font-medium text-base">
                    {{ $t('testfleet.hr.remark.list.title') }}
                </h3>
                <div class="flex flex-col divide-y divide-neutral-50">
                    <RemarkComponent v-for="(remark) in remarks" :variant="'tertiary'" :remark="remark"
                        :key="remark.id">
                    </RemarkComponent>

                </div>
            </div>

        </Dialog>
    </div>
</template>

<script setup lang="ts">
// @ts-ignore
import Plus from "vue-material-design-icons/Plus.vue";
import { ref } from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";

// @ts-ignore
import MessageTextOutline from "vue-material-design-icons/MessageTextOutline.vue";
// @ts-ignore
import Check from "vue-material-design-icons/Check.vue";
//@ts-ignore
import Loading from 'vue-material-design-icons/Loading.vue'
import { Bike } from '@/services/bikes/types'
import RemarkComponent from '@/components/RemarkModal/Remark.vue'
import { useRemarkStore } from "@/stores/Remark/RemarkStore";
import { computed } from "vue";
import { PropType } from "vue";

const props = defineProps({
    bike: {
        type: Object as PropType<Bike>,
        required: true,
    },
    bookingId: {
        type: Number as PropType<number>,
        required: false,
    },
    header: {
        type: Boolean,
        default: false,
        required: true,
    }
});


const { getRemarks, createRemark } = useRemarkStore();

const remarks = computed(() => getRemarks(props.bike.deployment_bicycle_id!, props.bookingId));

const visible = ref(false);

const remark = ref<string>("");
const loading = ref(false);

const postRemark = async () => {
    if (!props.header) {
        visible.value = false;
    }
    loading.value = true;
    await createRemark(props.bike.deployment_bicycle_id!, remark.value, props.bookingId ?? null);
    // Update in the deployment store
    loading.value = false;
    remark.value = "";
};


</script>
