<template>
  <form-step>
    <form-layout class="col-span-2 h-full">
      <template v-slot:title>
        {{ $t("testfleet.booking.reserve.title") }}
      </template>

      <template v-slot:description>
        {{ $t("testfleet.booking.reserve.description") }}
      </template>

      <template v-slot:form>
        <div class="flex flex-col gap-8 pb-16 lg:pb-0 px-8">
          <!-- Location -->
          <Location :location="bike.location" v-if="bike.location" />
          <!-- Separator -->
          <hr v-if="bike.location && bike.availabilities.days.length > 0" />
          <!-- Pick up -->
          <div v-if="bike.availabilities.days.length > 0" class="flex flex-col gap-8">
            <div class="flex flex-col gap-8">
              <div class="space-y-3">
                <div class="flex gap-2 items-center">
                  <div class="bg-tertiary rounded-full p-1 flex items-center justify-center">
                    <CalendarClock class="text-white" :size="16" />
                  </div>
                  <h2>
                    {{ $t("testfleet.booking.reserve.pickup.title") }}
                  </h2>
                </div>
                <p class="font-roboto text-neutral-700 text-sm leading-5 font-normal"
                  v-html="$t('testfleet.booking.reserve.pickup.description', {pickup_start: deploymentStore.getDeployment?.pickup_timeslot_start, pickup_end: deploymentStore.getDeployment?.pickup_timeslot_end, dropoff_start: deploymentStore.getDeployment?.dropoff_timeslot_start, dropoff_end: deploymentStore.getDeployment?.dropoff_timeslot_end, })"></p>
              </div>
              <div class="flex flex-col gap-2">
                <!-- Days -->
                <h3 class="text-base font-roboto">
                  {{ $t("testfleet.booking.reserve.pickup.day") }}
                </h3>
                <!-- Options -->
                <div class="flex" v-for="(day, index) in bike?.availabilities.days" :key="`day-${index}`"
                  :data-cy="`slot-${index}`">
                  <Field name="slot.pickup.date" type="radio" :value="day.pickup.date" :id="day.pickup.date"
                    class="hidden peer" />
                  <label @click="onChangePickupDay(index)" :for="day.pickup.date" data-cy="pickup-day"
                    class="font-roboto text-neutral-700 w-full px-4 py-2 rounded-full peer-checked:bg-tertiary-100 [&>*:first-child]:hidden peer-checked:[&>*:first-child]:block text-center border border-neutral-300 peer-checked:border-tertiary-200 inline-flex items-center justify-center gap-2">
                    <Check :size="16" />
                    <span>
                      {{
          $t("testfleet.booking.reserve.from")
        }}
                      <span>
                        {{ new Date(day.pickup.date).toLocaleDateString($tolgee.getLanguage() as string, {
          weekday: "short",
          month: "long",
          day: "numeric",
        }) }}
                      </span>
                      {{
          $t("testfleet.booking.reserve.to")
        }}
                      <span>
                        {{ new Date(day.dropoff.date).toLocaleDateString($tolgee.getLanguage() as string, {
          weekday: "short",
          month: "long",
          day: "numeric",
        }) }}
                      </span>
                    </span>
                  </label>
                </div>
                <!-- Error -->
                <span v-if="alreadyBookedOnTimeSlotError" class="text-error-500 text-sm font-roboto text-center">
                  {{ $t("testfleet.booking.reserve.pickup.error") }}
                </span>
              </div>
            </div>
          </div>
          <EmptyState v-else :title="$t('testfleet.booking.reserve.empty.title')"
            :description="$t('testfleet.booking.reserve.empty.description')" class="text-center" />
        </div>
      </template>
    </form-layout>
  </form-step>
</template>

<script setup lang="ts">
import FormStep from "@/components/Form/FormStep.vue";
//@ts-ignore
import CalendarClock from "vue-material-design-icons/CalendarClock.vue";
//@ts-ignore
import Check from "vue-material-design-icons/Check.vue";
import FormLayout from "@/components/Form/FormLayout.vue";
import Location from "@/components/Booking/Location/Location.vue";
import { Bike } from "@/services/bikes/types";
import { PropType } from "vue";
import { Field, useField } from "vee-validate";
import EmptyState from "@/components/EmptyState/EmptyState.vue";
import { useUserStore } from "@/stores/User/UserStore";
import { computed } from "vue";
import { ref } from "vue";
import { BookingStatus } from "@/services/booking/types";
import moment from "moment";
import { useDeploymentStore } from "@/stores/Deployment/DeploymentStore";

const { bike } = defineProps({
  bike: {
    type: Object as PropType<Bike>,
    required: true,
  },
});

const userStore = useUserStore();
const deploymentStore = useDeploymentStore();

const user = computed(() => userStore.getUser);

const pickupDayField = useField<string>("slot.pickup.date");
const pickupTimeField = useField<number>("slot.pickup.timeslot");
const dropOffDayField = useField<string>("slot.dropoff.date");
const dropOffTimeField = useField<number>("slot.dropoff.timeslot");
const alreadyBookedOnTimeSlotError = ref(false);

const onChangePickupDay = (index: number) => {
  // get the selected day
  const selected = bike?.availabilities.days[index];

  // Check if the current user is logged in
  // And check if user has another booking at this timeslot
  if (user.value) {
    if (user.value.bookings.length > 0) {
      // Check if there is a booking at the selected timeslot
      const booking = user.value.bookings.find(
        (booking) =>
          moment(booking.start_date).format('YYYY-MM-DD') === selected.pickup.date &&
          booking.status !== BookingStatus.Cancelled
      );
      if (booking) {
        alreadyBookedOnTimeSlotError.value = true;
        // Reset the fields
        pickupDayField.resetField();
        pickupTimeField.resetField();
        dropOffDayField.resetField();
        dropOffTimeField.resetField();
        return;
      }
    }
  }
  // reset the error
  alreadyBookedOnTimeSlotError.value = false;
  // set the pickup day
  pickupDayField.setValue(selected.pickup.date);
  // set the pickup time
  pickupTimeField.setValue(selected.pickup.timeslot);
  // set the drop off day
  dropOffDayField.setValue(selected.dropoff.date);
  // set the drop off time
  dropOffTimeField.setValue(selected.dropoff.timeslot);
};


</script>
