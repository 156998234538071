import AxiosClient from "@/services/api";
import { Deployment, DeploymentUpdateDto } from "./types";


const getIdsForUser = async () : Promise<number[]> => {
  const response = await AxiosClient.get("/deployments/user");
  return response.data;
}

const getById = async (id: number) : Promise<Deployment|null> => {
  try {
    const response = await AxiosClient.get(`/deployments/${id}`);
    return response.data;
  } catch {
    return null;
  }
}

const getByCode = async (code: string) : Promise<Deployment|null> => {
  try {
    const response = await AxiosClient.get(`/deployments/code/${code}`);
    return response.data;
  } catch {
    return null;
  }
};

const updateDeployment = async (id: number, deployment: DeploymentUpdateDto) => {
  return AxiosClient.put(`/deployments/${id}`, {...deployment});
}

const fetchBookings = async (deploymentId: number) => {
  return AxiosClient.get(`/deployments/${deploymentId}/bicycles/bookings`);
};

const confirmHRPickup = (bookingId: number, picked_up_helmet: boolean) => {
  return AxiosClient.post(`/bookings/${bookingId}/admin-pickup`, { picked_up_helmet });
};

const confirmHRDropoff = (bookingId: number) => {
  return AxiosClient.post(`/bookings/${bookingId}/admin-dropoff`);
};

const cancel = (bookingId: number) => {
  return AxiosClient.put(`/bookings/${bookingId}/admin-cancel`);
}

const inactivateBike = (deploymentId: number, bikeId: number) => {
  return AxiosClient.put(`/deployments/${deploymentId}/bicycles/${bikeId}/deactivate`);
}

const attachUserToDeployment = (code: string) => {
  return AxiosClient.post(`/deployments/attach-user`, { code });
}


export const DeploymentService = { getIdsForUser, getById, getByCode, updateDeployment, fetchBookings, confirmHRPickup, confirmHRDropoff, cancel, inactivateBike, attachUserToDeployment };
