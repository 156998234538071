<template>
    <div>
        <button
            v-tooltip.top="$t('testfleet.hr.calendar.button.cancel.confirm.title')"
            class="flex items-center justify-center text-sm font-medium px-2 py-2 border border-error text-error rounded-full enabled:hover:bg-error enabled:hover:text-white font-light w-full disabled:opacity-50"
            :class="{ 'bg-error text-white cursor-default': BookingStatus.Cancelled == props.booking.status || booking.admin_dropoff_confirmed }"
            v-if="showCancel()"
            @click="props.booking.status !== BookingStatus.Cancelled && (visible = true)">
            <TrashCanOutline :size="20" />
        </button>
        <Dialog v-model:visible="visible" modal :header="$t('testfleet.hr.calendar.button.cancel.confirm.title')" :draggable="false"
            :style="{ width: '48rem' }">

            <span class="text-neutral-400 text-md font-light">{{
                $t('testfleet.hr.calendar.button.cancel.confirm.subtitle', { bike: bikeName, name: person }) }}</span>
            <div class="flex justify-end gap-2 mt-5">
                <!-- Go Back Button -->
                <button
                    class="w-fit flex items-center justify-center gap-2 text-sm font-medium px-4 py-2 rounded-full font-light"
                    @click="visible = false">
                    <span>{{ $t('testfleet.hr.calendar.button.cancel.confirm.cancel') }}</span>
                </button>
                <!-- Confirm Button -->
                <button
                    class="flex items-center justify-center gap-2 text-sm font-medium px-4 py-2 border border-error text-error rounded-full enabled:hover:bg-error enabled:hover:text-white font-light disabled:opacity-50"
                    @click="deactivate">
                    <span>{{ $t('testfleet.hr.calendar.button.cancel.confirm.confirm') }}</span>
                </button>
            </div>
        </Dialog>

    </div>

</template>

<script setup lang="ts">
import { useDeploymentStore } from '@/stores/Deployment/DeploymentStore';
import { Booking, BookingStatus } from '@/services/booking/types';
import { Bike } from '@/services/bikes/types';
import Dialog from "primevue/dialog";
import { computed } from 'vue';
import { ref } from 'vue';
// @ts-ignore
import TrashCanOutline from 'vue-material-design-icons/TrashCanOutline.vue'

const props = defineProps<{
    booking: Booking,
    bicycle: Bike
}>();

const visible = ref(false);

const deploymentStore = useDeploymentStore();

const bikeName = computed(() => {
    return props.bicycle.brand + ' ' + props.bicycle.model + ' (' + props.bicycle.bicycle_code + ')';
});

const person = computed(() => {
    return props.booking.user?.name + ' ' + props.booking.user?.last_name;
});

const deactivate = async () => {
    await deploymentStore.cancel(props.booking.id)
    visible.value = false;
}

const showCancel = () : boolean => {
    // Show when afhaling bevestigd = false OR inleveren bevestigd = false AND not canceled = true
    return props.booking.status !== BookingStatus.Cancelled && (!props.booking.admin_pickup_confirmed);
}
</script>