import AxiosClient from "../api";


/**
 * Create a remark for a bike
 * @param bookingId 
 * @param remark 
 * @returns 
 */
const createRemark = async (bikeId : number, remark : string, bookingId: number | null) => {
    try {
        const response = await AxiosClient.post(`/remarks/${bikeId}`, { remark, booking_id: bookingId });
        return response.data;
    } catch (error) {
        return null;
    }
}

const fetchRemarksByBicycleId = async (bikeId: number) => {
    try {
        const response = await AxiosClient.get(`/remarks/deployment_bicycle/${bikeId}`);
        return response.data;
    } catch (error) {
        return null;
    }
}

const fetchRemarksByBookingId = async (bookingId: number) => {
    try {
        const response = await AxiosClient.get(`/remarks/booking/${bookingId}`);
        return response.data;
    } catch (error) {
        return null;
    }
}

export const RemarkService = {
    createRemark,
    fetchRemarksByBicycleId,
    fetchRemarksByBookingId
};
