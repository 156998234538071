<template>
    <div class="flex flex-col items-center justify-center gap-2 h-full w-full">
        
        <h1>
            {{ props.title }}
        </h1>
        <p>
            {{ props.description }}
        </p>
    </div>
</template>

<script setup lang="ts">

  const props = defineProps({
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
  })

</script>
