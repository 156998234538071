import { defineStore } from "pinia";
import { User } from "../../services/user/types";
import { useDeploymentStore } from "../Deployment/DeploymentStore";
import { UserService } from "@/services/user";
import { useBikeStore } from "../Bike/BikeStore";
import CryptoJS from "crypto-js";
import { LocalStorageService } from "@/services/localstorage";

interface UserState {
  user: User | null;
}

export const useUserStore = defineStore("UserStore", {
  state: (): UserState => ({
    user: null
  }),

  getters: {
    getUser(): User | null {
      return this.user;
    },
    isDeploymentAdmin(): boolean {
      let currentDeploymentId = useDeploymentStore().getDeployment?.id;
      const jwtPayload = LocalStorageService.getJWTPayload();
      return jwtPayload?.deployments.includes(currentDeploymentId);
    },
    getFullName(): string {
      return this.user ? `${this.user.name} ${this.user.last_name}` : "";
    },
    getEmail(): string {
      return this.user ? this.user.email : "";
    },
    getUserIntercomHash() : string {
      let email = this.user ? this.user.email : undefined;
      if (!email) return "";
      // Create HMAC SHA256 hash of user email to use as Intercom user hash
      let hash = CryptoJS.HmacSHA256(email, import.meta.env.VITE_INTERCOM_HMAC_SECRET);
      return hash.toString();
    }
  },

  actions: {
    async fetchUser(): Promise<void> {
      this.user = await UserService.fetchUser();
      if (this.user) {
        await useDeploymentStore().getActiveForUser();
        await useBikeStore().fetchDeploymentBikes();
      }
    },
    clearUser(): void {
      this.user = null;
    }
  },
});
