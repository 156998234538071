<template>
    <div>
        <button
            class="flex items-center justify-center gap-2 text-sm font-medium px-4 py-2 border border-neutral-100 text-neutral-500 rounded-full enabled:hover:bg-neutral-300 enabled:hover:text-white font-light w-full disabled:opacity-50"
            :class="{ 'bg-neutral-300 text-white cursor-default': bike.inactive }"
            @click="!bike.inactive && (visible = true)">
            <Cancel :size="20" />
            <!-- Conditional Button message -->
            <span v-if="bike.inactive">
                {{ $t('testfleet.hr.calendar.button.inactive') }}
            </span>
            <span v-else>
                {{ $t('testfleet.hr.calendar.button.inactivate') }}
            </span>
        </button>
        <Dialog v-model:visible="visible" modal :header="$t('testfleet.hr.calendar.button.inactivate.confirm.title', {bike: bikeName})" :draggable="false"
            :style="{ width: '48rem' }">

            <span class="text-neutral-400 text-md font-light">{{ $t('testfleet.hr.calendar.button.inactivate.confirm.subtitle', {bike: bikeName}) }}</span>
            <div class="flex justify-end gap-2 mt-5">
                <!-- Go Back Button -->
                <button
                    class="w-fit flex items-center justify-center gap-2 text-sm font-medium px-4 py-2 rounded-full font-light"
                    @click="visible = false">
                    <span>{{ $t('testfleet.hr.calendar.button.inactivate.confirm.cancel') }}</span>
                </button>
                <!-- Confirm Button -->
                <button
                    class="flex items-center justify-center gap-2 text-sm font-medium px-4 py-2 border border-error text-error rounded-full enabled:hover:bg-error enabled:hover:text-white font-light disabled:opacity-50"
                    @click="deactivate">
                    <span>{{ $t('testfleet.hr.calendar.button.inactivate.confirm.confirm') }}</span>
                </button>
            </div>
        </Dialog>

    </div>

</template>

<script setup lang="ts">
import { Bike } from '@/services/bikes/types';
import { useDeploymentStore } from '@/stores/Deployment/DeploymentStore';
import Dialog from "primevue/dialog";
import { computed } from 'vue';
import { ref } from 'vue';
// @ts-ignore
import Cancel from "vue-material-design-icons/Cancel.vue";

const { bike } = defineProps<{
    bike: Bike
}>(
);

const visible = ref(false);

const deploymentStore = useDeploymentStore();

const bikeName = computed(() => {
    return bike.brand + ' ' + bike.model + ' (' + bike.bicycle_code + ')';
});

const deactivate = async () => {
    await deploymentStore.inactivateBike(bike.id);
    visible.value = false;
}
</script>