<template>
    <form-step class="px-8">
        <form-layout class="col-span-2 h-full">
            <template v-slot:title>
                {{ $t("testfleet.booking.bikeDetail.title") }}
            </template>

            <template v-slot:description>
                {{ $t("testfleet.booking.bikeDetail.description") }}
            </template>
            <template v-slot:form>
                <div class="space-y-8 pb-8 lg:pb-0 px-8">
                    <BikeInfoCard class="" v-if="bike" :bike="bike" />
                    <div>
                        <div class="pb-3">
                            <h3 class="leading-[48px]">
                                {{ $t("testfleet.booking.bikeDetail.availability") }}
                            </h3>
                            <span class="font-roboto text-neutral-700 leading-5 text-sm !font-normal">
                                {{ $t("testfleet.booking.bikeDetail.availabilityDescription") }}
                            </span>
                        </div>
                        <Calendar v-if="bike.availabilities" :availabilities="bike.availabilities" />
                    </div>
                </div>
            </template>
        </form-layout>
        <LeasePriceDisclaimer class="mb-6" v-if="shouldShowLeasePriceDisclaimer" />
    </form-step>
</template>

<script setup lang="ts">
import FormStep from '@/components/Form/FormStep.vue'
import FormLayout from '@/components/Form/FormLayout.vue'
import Calendar from '@/components/Calendar/Calendar.vue'
import BikeInfoCard from '@/components/BikeInfoCard/BikeInfoCard.vue'
import { Bike } from '@/services/bikes/types';
import { PropType } from 'vue';
import LeasePriceDisclaimer from '@/components/LeasePriceDisclaimer/LeasePriceDisclaimer.vue';
import { computed } from 'vue';
import { useTolgee } from '@tolgee/vue';
import { getCurrentLocaleValue } from '@/utils/i18n';

const { bike } = defineProps({
    bike: {
        type: Object as PropType<Bike>,
        required: true
    }
})

const shouldShowLeasePriceDisclaimer = computed(() => {
    // Check if one of the bikes have a lease price
    const lp = getCurrentLocaleValue(bike.lease_price, useTolgee().value.getLanguage());
    return lp !== null && lp !== undefined && lp !== '';
})

</script>
