import { Booking, BookingStatus } from "@/services/booking/types";
import { useUserStore } from "@/stores/User/UserStore";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

const getBooking = async (id: string): Promise<Booking | undefined> => {
  const userStore = useUserStore();
  return userStore.user?.bookings.find((b) => b.id === Number(id));
};

export const PickupGuard = async (
  to: RouteLocationNormalized,
  // @ts-ignore
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const booking = await getBooking(to.params.id as string);

  if (booking && booking.status === BookingStatus.Confirmed) {
    return next();
  }

  return next({ path: "/my-bookings", query: to.query });
};

export const DropoffGuard = async (
  to: RouteLocationNormalized,
  // @ts-ignore
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const booking = await getBooking(to.params.id as string);

  if (booking && booking.status === BookingStatus.PickedUp) {
    return next();
  }

  return next({ path: "/my-bookings", query: to.query });
};

export const CancelGuard = async (
  to: RouteLocationNormalized,
  // @ts-ignore
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const booking = await getBooking(to.params.id as string);

  if (booking && booking.status === BookingStatus.Confirmed) {
    return next();
  }

  return next({ path: "/my-bookings", query: to.query });
};
